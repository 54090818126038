<template>
  <div id="Blog">
    <div class="blog-cont">
      <div class="titulo-b-c">
        <p class="titulo"><span>05</span>Magazine.</p>
      </div>
      <div class="blogs-items-btn-cont">
        <div class="blog-items">
          <a 
          class="blogFrame"
            v-for="(bl, i) in allblogs.slice(0,3)"
            :key="i" :href="'/magazine/'+ bl.url">
          <div
            class="item"
          >
            <a :href="'/magazine/'+ bl.url">
              <div class="text-cont">
                <p class="cat purple"
                  :class="{
                    bg_cat1: bl.category_name == 'MARKETING',
                    bg_cat2: bl.category_name == 'DEVELOPMENT',
                    bg_cat3: bl.category_name == 'DESIGN',
                    bg_cat4: bl.category_name == 'PHOTOGRAPHY',
                    bg_cat5: bl.category_name == 'MEET THE BOLDS',
                    bg_cat6: bl.category_name == 'ENTREPREURNSHIP',
                  }">
                  {{ bl.category_name | split }}
                </p>
                <!--<p class="cat pink" v-else-if="bl.categoria == 'Marketing'">
                  {{ bl.categoria }}
                </p>
                <p
                  class="cat darkpurple"
                  v-else-if="bl.categoria == 'Desarrollo'"
                >
                  {{ bl.categoria }}
                </p>-->
                <p class="fecha">{{ bl.dateblog }}</p>
                <p class="titulo-b">
                  {{ bl.title }}
                </p>
              </div>
            </a>
          </div>  
          <div class="frame-bk" :style="{ 'background-image': 'url(' +  urlpath.url()+'/blog-img/'+bl.image + ')' }"></div>          
          </a>

        </div>
        <div class="btn-cont">
          <a href="/magazine">
            <!--<img
              src="../../assets/arrow-right.svg"
              alt="arrow"
              width="48"
              height="48"
            />-->
            <div class="flecha-ar"></div>
            <p>Todas las entradas</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from 'vuex';
let urlpath = require('../../global/url');

export default {
  data() {
    return {
        urlpath:urlpath,
      blog: [
        {
          titulo:
            "Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery.",
          fecha: "11/marzo/2022",
          categoria: "Diseño",
          img: require("../../assets/blog-1.png"),
          link: "/",
        },
        {
          titulo:
            "Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery.",
          fecha: "11/marzo/2022",
          categoria: "Marketing",
          img: require("../../assets/blog-2.png"),
          link: "/",
        },
        {
          titulo:
            "Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery.",
          fecha: "11/marzo/2022",
          categoria: "Desarrollo",
          img: require("../../assets/blog-3.png"),
          link: "/",
        },
      ],
    };
  },
  async created() {
    
         await this.getAllInfoB2('blog')
  },
    computed:{

         allblogs() {
            let array = this.$store.getters["blog2/data"];
            let encontrados=[]
            for (const item of array) {
                if(item.orden !='NA'){
                    encontrados.push(item)
                }
            }
            this.destacados = encontrados
            this.destacados.sort(function(a, b){
              return a.orden - b.orden
            })
            return this.destacados
        },
        
    },
    methods:{
        ...mapActions('blog2', ['getAllInfoB2']),
    },
  filters: {
    split: (value) => {
      if (!value) return "";
      value = value.toString();

      let splitStr = value.toLowerCase().split(" ");
      let len = splitStr.length;
      let i;

      if (len == 1) {
        return splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);
      }

      for (i = 0; i < len; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      let nameCap = splitStr.join(" ");
      let newName = nameCap.split(" ").slice(0).join(" "); //cantidad de palabras quitadas.

      return newName;
    },
  },
};
</script>
<style scoped>
#Blog {
  background-color: white;
  padding: 14.35546875vh;
  height: inherit;
    display: flex;
    align-items: center;
}
.blog-cont {
    position: relative;
    transition: 1s;
}
.blog-items {
  display: flex;
  position: relative;
  top: 0.6vh;
  width: 190.234375vh;
  justify-content: space-between;
  /*transition: .5s ease-out;*/
}
.item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 45.007813vh;
  height: 50.96875vh;
  padding: 5.46875vh 7.6171875vh;
  position: absolute;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 35.85%, #170536 98.11%);
  /*  background-size: 200%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 5.46875vh 7.6171875vh;
  transition: 0.5s;*/
}
.frame-bk{
    width: 60.007813vh;
    height: 61.96875vh;
    background-size: 223%;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.5s;
}
.blogFrame:hover  .frame-bk {
 /* background-size: 215%;*/
    background-size: 235%;
}
.titulo-b-c p.titulo {
  font-family: Gramatika-Medium;
  font-size: 9.765625vh;
  line-height: 0;
  top: 0;
  left: 13.4765625vh;
  letter-spacing: -0.015em;
  color: #987ef3;
  margin: 0;
  z-index: 100;
  position: relative;
  width: 62vh;
  display: none;
}
.titulo-b-c p span {
    font-family: IBM Plex Mono;
    font-style: normal;
    font-weight: bold;
    font-size: 1.26953125vh;
    top: -1.7vh;
    position: relative;
    width: 3.2vh;
}
.text-cont {
  display: flex;
  flex-wrap: wrap;
  width: 41.9921875vh;
}
.blogs-items-btn-cont {
  display: flex;
  align-items: center;
}
.btn-cont a {
  display: none;
  align-items: center;
  margin-left: 11.5234375vh;
  width: 21.316406vh;
  justify-content: space-between;
}

.flecha-ar{
  width: 5.6875vh;
  height: 5.6875vh;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url('../../assets/arrow-right.svg');
  transition: .5s;
}
.btn-cont:hover .flecha-ar{
  background-image: url('../../assets/btn-pink.svg');
}
.btn-cont a img {
  width: 4.6875vh;
  height: auto;
}
p.cat {
  margin: 0;
  font-family: Gramatika-Black;
  font-size: 1.46484375vh;
  line-height: 1.464844vh;
  color: #ffffff;
  padding: 0 2.34375vh;
  margin-right: 2.34375vh;
  height: 3.3vh;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
p.fecha {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  font-size: 1.26953125vh;
  line-height: 2.36953125vh;
  color: #ffffff;
  margin: 0;
}
p.titulo-b {
  font-family: Gramatika-Bold;
  font-size: 2.44140625vh;
  line-height: 110.3%;
  color: #ffffff;
  margin: 4.39453125vh 0 0;
}
.purple {
  background-color: #851bff;
}
.pink {
  background-color: #ef67c2;
}
.darkpurple {
  background-color: #3702fb;
}
.btn-cont a p {
  font-family: Gramatika-Black;
  font-size: 1.5625vh;
  line-height: 127.3%;
  text-align: center;
  color: #b2acfc;
    padding-left: 2vh;
}
@media (max-width: 768px) {
  #Blog {
    background-color: white;
    padding: 0;
    align-items: flex-start;
    height: 100%;
  }
  .blog-cont{
    margin-left: 14vh;
    padding-top: 23.582609vh;
  }
  .blog-items {
    display: flex;
    position: relative;
    top: 0;
    width: 120vh;
    justify-content: space-between;
  }
  .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 28.125vh;
    height: 54.6875vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    padding: 5.46875vh 4.7554vh;
  }
  .item:hover{
    background-size: cover;
  }
  .blog-items a{
    overflow: hidden;
  }
  .frame-bk{
    width: 37.6358vh;
    height: 65.625vh;
    background-size: 322%;
  }
  a.blogFrame:hover .frame-bk{
    background-size: 295%;
  }
  p.titulo-b {
    font-size: 2.0380vh;
    margin: 4.39453125vh 0 0;
    width: 28vh;
  }
  .text-cont {
    width: fit-content;
  }
  p.fecha {
    font-size: 1.108374384236453vh;
    line-height: 2.608374vh;
  }
  p.cat {
    margin: 0;
    font-family: Gramatika-Black;
    font-size: 1.46484375vh;
    line-height: 1.464844vh;
    color: #ffffff;
    padding: 0 2.955vh;
    margin-right: 2.34375vh;
    height: 2.5862068965517238vh;
  }
  .btn-cont a {
    margin-left: 26.523438vh;
}
}
</style>