import { render, staticRenderFns } from "./About.vue?vue&type=template&id=38e92749&scoped=true"
import script from "./About.vue?vue&type=script&lang=js"
export * from "./About.vue?vue&type=script&lang=js"
import style0 from "./About.vue?vue&type=style&index=0&id=38e92749&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38e92749",
  null
  
)

export default component.exports