<template >
  <div id="AcercaDe" class="AcercaDe">
    <div class="scroll-x">
      <div class="video-sec" >
        <!--<iframe src="https://giphy.com/embed/MReXEf3tlqwY1Ilysi" width="480" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
        <p><a href="https://giphy.com/gifs/MReXEf3tlqwY1Ilysi">via GIPHY</a></p>
        <video class="video-head" loop autoplay muted>
          <source src="../../assets/video-head.mp4" type="video/mp4" />
        </video>-->
        <img src="../../assets/head.png" alt="" class="video-head">
        <!-- <img
        src="../../assets/video-head.svg"
        alt="head"
        class="video-head"
        width="939"
        height="939"

        250
      />-->
      </div>
      <div class="about-t-c">
        <div class="about-t-h">
          <p class="titulo aboutTitle"><span>02</span>About</p>
        </div>
        <div
          class="about-txt-c"
          :class="{
            ontop: scrollP < maxminwidth(3800, 2500),
            onbottom: scrollP > maxminwidth(8500, 7500),
          }"
        >
          <main>
            <div
              class="listz rellax listAbout"
              style="top: 0px"
              id="listz"
              :class="{ animation1: scrollP > 3500 }"
            >
              <p class="primer-p">
                En Legrafica nos mueve la creatividad, los retos, y evolucionar
                junto con el mundo digital.
              </p>
              <p class="segundo-p">
                No nos quedamos quietos y siempre exploramos nuevas formas en
                las que tu marca puede resaltar, manteniendo presentes nuestros
                valores como agencia.
              </p>
            </div>
          </main>
        </div>
      </div>
    </div>

    <!--FOTOS ABOUT-->
  </div>
</template>
<script>
export default {
  data() {
    return {
      /*scrollPosition: null,*/
    };
  },
  props: {
    scrollP: 0,
  },
  methods: {
    maxminwidth(pc, mo) {
      let x = window.matchMedia("(max-width: 768px)");
      let y = window.matchMedia("(min-width: 768px)");
      if (x.matches) {
        return mo;
      } else if (y.matches) {
        return pc;
      }
    },
    updateScrollX() {
      this.scrollPosition = window.scrollX;
    },
    /* setStickyContainersSize(){
        document.querySelectorAll('.about-txt-c').forEach(function(container){
            let stikyContainerHeight = (container.querySelector('main').offsetWidth + window.innerHeight);
            container.setAttribute('style', 'height: ' + stikyContainerHeight + 'px');
        });
    }*/
  },
  mounted() {
    window.addEventListener("scroll", this.animX);
    window.addEventListener("scroll", this.updateScrollX);
    window.addEventListener("scroll", this.setStickyContainersSize);
    /*
  animation-delay: calc(var(--scroll) * -1s);
  
  
  window.addEventListener(
      "scroll",
      () => {
        document.body.style.setProperty(
          "--scroll",
          window.pageYOffset / (document.body.offsetHeight - window.innerHeight)
        );
      },
      false
    );*/
  },
  destroy() {
    window.removeEventListener("scroll", this.animX);
    window.removeEventListener("scroll", this.updateScrollX);
    window.addEventListener("scroll", this.updateScrollX);
  },
};
</script>
<style scoped>
#AcercaDe {
  display: flex;
  padding-left: 9.47265625vh;
  /*max-width: 700vh;
  width: 700vh;*/
    max-width: 340vh;
    width: 340vh;
  background-color: #b2acfc;
  transition: .35s;
  height: inherit;
}
.scroll-x {
  display: flex;
    position: relative;
  /*background-color: #b2acfc;*/
  left: 3vh;
  width: 182vh;
  /*transition: .35s;*/
  transition: .25s cubic-bezier(0.49, 0.47, 0.68, 0.68);
}
.video-head {
  width: 91.69921875vh;
  height: auto;
  transition: .5s;
}
.titulo {
  font-family: "Gramatika-Medium";
  font-size: 9.765625vh;
  line-height: 103.8%;
  text-align: center;
  color: #ffffff;
  margin: 0;
  position: relative;
  z-index: 99;
  left: 0;
  display: flex;
}
.titulo span {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: bold;
  font-size: 1.26953125vh;
  line-height: 103.8%;
  text-align: center;
  color: #ffffff;
  margin-right: 2.19921875vh;
  position: relative;
  top: 2.3vh;
}
.about-t-h {
  width: 100%;
  padding-top: 16.2109375vh;
  height: 12.019531vh;
 /* background-color: #b2acfc;*/
  position: relative;
  z-index: 99;
}
.about-t-c {
  padding-top: 0;
  padding-right: 33.69140625vh;
  position: relative;
  transition: .5s;
    z-index: 105;
}
.about-txt-c {
  overflow: hidden;
  overflow-y: hidden;
  height: 70vh;
  position: sticky;
  position: -webkit-sticky;
  transition: 0.5s;
  /* animation: lineXtwo 2s linear forwards;*/
}
.scrolleable {
  /* animation: lineX 2s linear forwards;*/
  /* overflow-y: overlay;*/
}
.about-txt-c::-webkit-scrollbar {
  width: 2vh !important;
  background: transparent;
  height: 1.26953125vh;
  margin: 0 0 3vh;
  z-index: 999;
  position: relative;
}

.about-txt-c::-webkit-scrollbar-track {
  margin: 0 3vh 3vh;
  height: 2vh;
  width: 2vh;
  border-radius: 2vh;
  background: transparent;
}

.about-txt-c::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 2vh;
  border: 0.3vh solid transparent;
}
.about-txt-c p {
  font-family: "Gramatika-Medium";
  font-size: 4.39453125vh;
  line-height: 4.79453125vh;
  color: #ffffff;
  width: 64.6484375vh;
  margin: 0;
}
.about-txt-c p:nth-child(1) {
  margin-top: 15.277344vh;
  /*margin-top: 8.277344vh;*/
  opacity: 1;
  transition: 1s;
}
.about-txt-c p:nth-child(2) {
  /*padding-top: 35.703125vh;*/
  padding-top: 13.703125vh;
  /* margin-top: 28.703125vh;*/
  /*padding-bottom: 28.703125vh;*/
  opacity: 0;
  transition: 1s;
}
.ontop .listz {
  transition: .3s ease-out;
  /*margin-top: 45.5561vh !important;*/
   margin-top: 0vh !important;
  position: relative;
}
.onbottom .listz {
  transition: 0.5s;
  margin-top: -74.1125vh !important;
}

.about-txt-c.scrolleable p:nth-child(1) {
  /* opacity: 0;*/
}
.about-txt-c.scrolleable p:nth-child(2) {
  /*opacity: 1;*/
}

.listz {
  background: transparent;
}
.listz.animation1 {
  background: transparent;
}

@keyframes lineX {
  0% {
    margin-top: -0vh;
  }
  30% {
    margin-top: -35vh;
  }
  100% {
    margin-top: -70vh;
  }
}
@keyframes lineXtwo {
  0% {
    margin-top: -70vh;
  }
  30% {
    margin-top: -35vh;
  }
  100% {
    margin-top: -0vh;
  }
}

@media (max-width: 768px) {
  .titulo {
    font-size: 5.541871921182265vh;
    width: 50vh;
    text-align: left;
    flex-direction: column;
    margin-left: 6.2807881773399vh;
  }
  .titulo span {
    font-size: 1.6009852216748766vh;
    color: #ffffff;
    width: fit-content;
    margin-bottom: 2vh;
  }
  .video-sec,
  .video-head {
    width: 62.078818vh;
  }
  .video-head {
    position: relative;
    transition: 1s;
  }
  .scroll-x {
    left: 0;
    max-width: 100vh;
    width: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition: .56s;
  }
  #AcercaDe {
    display: flex;
    padding-left: 0;
    /*width: 182vh;*/
    width: 290vh;
    /*max-width: 800vh;
    width: 800vh;
    background-color: #b2acfc;*/
  }
  .about-t-c {
    transition: .5s;
    position: relative;
    left: 5.6vh;
    top: -63vh;
    padding-top: 0;
    padding-right: 1.7241379310344827vh;
    background: linear-gradient(180deg, rgba(178,172,252) 31.12%, rgba(178, 172, 252, 0) 53%);
  }
  .about-txt-c p {
    font-size: 3.3967vh;
    line-height: 133.3%;
    width: 36.330049vh;
  }
  .segundo-p {
    padding-top: 10.703125vh!important;
    width: 41vh!important;
}
  .ontop .listz {
    transition: .25s cubic-bezier(0.39, 0.58, 0.57, 1);
    margin-top: 30.5561vh !important;
    margin-left: 6.2807881773399vh;

}p.titulo.aboutTitle {
    display: none;
}
}
</style>