<template>
  <div id="Title" class="iguanafacha">
    <div class="title-cont">
      <p id="wuno" class="wtuno wt">Digital</p>
      <p id="wdos" class="wtdos wt">transformation</p>
      <p id="wtres" class="pink wttres wt">is not an</p>
      <p id="wcuatro" class="pink wtcuatro wt">option</p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
#Title {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 26.66015625vh;
    height: inherit;
}
.title-cont p {
  font-family: Gramatika-Medium;
  font-size: 17.578125vh;
  line-height: 99.8%;
  color: #b2acfc;
  margin: 0;
  /*transition: .5s ease-out;*/
}
.title-cont p.pink {
  color: #ffb6ea;
}
.wt{
  position: relative;
}
.iguanafacha{
  transition: .5s;
}
@media (max-width: 768px) {
  #Title {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 7.270936vh 0 25.270936vh;
  /*background-image: url('../../assets/iguana-facha.svg');
  background-size: 79.522167vh;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;*/
  }
  .title-cont p {
    font-size: 6.7934vh;
    line-height: 99.8%;
  }
  p.titulo {
    font-size: 6.773399014778325vh;
}
}
</style>