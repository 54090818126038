<template>
  <div id="Principal">
    <div class="sec-1">
      <div class="iguana-diva">
        <div class="text tcPrincipal pc">
          <p>We live in</p>
          <p>a digital world<span class="gn">_</span></p>
        </div>
        <div class="text tcPrincipal mo">
          <p>We live</p> <p>in
          a digital world<span class="gn">_</span></p>
        </div>
        <div class="news-cont newPrin ">
          <p>
            Somos una agencia de marketing y desarrollo digital, 
            especializada en crear experiencias integrales para tu marca y sus consumidores.
            <!--Hacemos de tu marca <span>una experiencia integral</span>, funcional
            e innovadora que crezca y cumpla sus objetivos en las plataformas
            digitales.-->
          </p>
        </div>
        <svg
          width="597"
          height="949"
          xmlns="http://www.w3.org/2000/svg"
          class="iguana iguanaAnimacion"
        >
          <image
            x="36"
            href="../../assets/iguana-1.png"
            style="contain: paint"
            width="597"
            height="949"
            class="pc"
          />
          <image
            x="36"
            href="../../assets/iguana-2.png"
            style="contain: paint"
            width="597"
            height="949"
            class="mo"
          />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      news: [
        {
          descr: "Los mejores consejos de emprendimiento por Miriam Lucero.",
          link: "/",
        },
      ],
    };
  },
};
</script>
<style scoped>
.pc{
  display: block;
}
.mo{
  display: none;
}
#Principal {
  height: 100vh;
  width: fit-content;
}

/**SEC-1 */
.sec-1 {
  height: 100vh;
  width: fit-content;
}
.iguana-diva {
  width: 115.4296875vh;
  height: 92.67578125vh;
  /*background-image: url("../../assets/iguana-1.png");
  background-repeat: no-repeat;
  background-size: 58.30078125vh;
  background-position-x: 27.83203125vh;
  margin: 0 9.5vh auto 18.5546875vh;*/
    margin: 0 41.554688vh auto 43.554688vh;
  position: relative;
  top: 7.25vh;
  transition:.5s;
}
svg.iguana {
  top: 0;
  position: absolute;
  margin: auto;
  left: 27.83203125vh;
  width: 61.300781vh;
  height: 92.67578125vh;
  transition:.5s;
}
svg.iguana image {
  width: 58.30078125vh;
  height: 92.67578125vh;
  animation-name: breath;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  transform: scale(1);
}
.iguana-diva .text {
  width: 109.66796875vh;
  padding-top: 30.56640625vh;
  z-index: 9;
  transition: .5s;
  position: relative;
}
.iguana-diva p {
  font-family: "Gramatika-Bold";
  font-size: 13.671875vh;
  line-height: 93.3%;
  text-align: center;
  color: #ffffff;

  margin: 0;
}
.news-cont {
  position: relative;
  z-index: 9;
  width: 33.027344vh;
  margin: 2vw 0 0 auto;
}
.news-cont p {
  font-family: "IBM Plex Mono";
  font-weight: normal;
  font-size: 1.3671875vh;
  line-height: 125.8%;
  letter-spacing: -0.045em;
  color: #ffffff;
  margin: 0;
  text-align: left;
}
.news-cont p span {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: bold;
  font-size: 1.3671875vh;
  line-height: 125.8%;
  letter-spacing: -0.045em;
  color: #ffb6ea;
  margin: 0;
  text-decoration-color: transparent;
}
.gn {
  animation-name: parpadeo;
  transition: 0;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes parpadeo {
  10% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes breath {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02) rotate(1.5deg);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
.pc{
  display: none;
}
.mo{
  display: block;
}
  .iguana-diva {
    width: fit-content;
    height: 78.07881773399014vh;
    margin: 0 0 auto 0vh;
    position: relative;
    top: 21.92118226600985vh;
  }
  svg.iguana {
    width: 51.6304vh;
    height: 86.277vh;
    left: 0vh;
  }
  svg.iguana image {
    width: 46.261084vh;
    height: 83.078818vh;
  }
  .iguana-diva .text {
    width: 39.778325123152705vh;
    padding-top: 22.783251231527093vh;
    /*padding-left: 6.650246305418718vh;*/
    padding-left: 9vw;
  }
  .iguana-diva p {
    font-size: 6.773399014778325vh;
    line-height: 6.773399014778325vh;
  }
  .news-cont {
    position: relative;
    z-index: 9;
    width: 27.02734375vh;
    /*margin: 3.940886699507389vh 0 0 13.315vh;*/
    margin: 3.940886699507389vh 0 0 23vw;
  }
  .news-cont p {
    font-size: 1.4778325123152707vh;
    line-height: 125.8%;
    text-align: center;
  }
}
</style>