var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Blog"}},[_c('div',{staticClass:"blog-cont"},[_vm._m(0),_c('div',{staticClass:"blogs-items-btn-cont"},[_c('div',{staticClass:"blog-items"},_vm._l((_vm.allblogs.slice(0,3)),function(bl,i){return _c('a',{key:i,staticClass:"blogFrame",attrs:{"href":'/magazine/'+ bl.url}},[_c('div',{staticClass:"item"},[_c('a',{attrs:{"href":'/magazine/'+ bl.url}},[_c('div',{staticClass:"text-cont"},[_c('p',{staticClass:"cat purple",class:{
                  bg_cat1: bl.category_name == 'MARKETING',
                  bg_cat2: bl.category_name == 'DEVELOPMENT',
                  bg_cat3: bl.category_name == 'DESIGN',
                  bg_cat4: bl.category_name == 'PHOTOGRAPHY',
                  bg_cat5: bl.category_name == 'MEET THE BOLDS',
                  bg_cat6: bl.category_name == 'ENTREPREURNSHIP',
                }},[_vm._v(" "+_vm._s(_vm._f("split")(bl.category_name))+" ")]),_c('p',{staticClass:"fecha"},[_vm._v(_vm._s(bl.dateblog))]),_c('p',{staticClass:"titulo-b"},[_vm._v(" "+_vm._s(bl.title)+" ")])])])]),_c('div',{staticClass:"frame-bk",style:({ 'background-image': 'url(' +  _vm.urlpath.url()+'/blog-img/'+bl.image + ')' })})])}),0),_vm._m(1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titulo-b-c"},[_c('p',{staticClass:"titulo"},[_c('span',[_vm._v("05")]),_vm._v("Magazine.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-cont"},[_c('a',{attrs:{"href":"/magazine"}},[_c('div',{staticClass:"flecha-ar"}),_c('p',[_vm._v("Todas las entradas")])])])
}]

export { render, staticRenderFns }