<template >
  <div id="TituloFijo" class="TituloFijo">
    <p class="titulo lastProTitulo"><span class="oa">{{this.number}}</span>{{this.titulo}}</p>
  </div>
</template>
<script>
export default {
data() {
    return {
        number:'01',
        titulo:'Latest projects.',
    }
},
  methods: {
    scrollX(event) {
      let divcont = document.getElementsByClassName("lastProTitulo")[0];
      let divcont2 = document.getElementsByClassName("TituloFijo")[0];
      let min768 = window.matchMedia("(min-width: 768px)");
      let oa = document.getElementsByClassName("oa")[0];
      let media768 = window.matchMedia("(max-width: 768px)");
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      if(media768.matches){
          if(offset <= 48){
              divcont.style.opacity = 0;
          }
          if(offset >= 48){
              divcont.style.opacity = 1;
              
          }
          if(offset >= 211){
              divcont.style.opacity = 0;
              
          }
          if(offset <= 277){
            this.number = '01',
            this.titulo = 'Latest projects.',
            divcont.style.color = '#ef67c2';  
            oa.style.color = '#ffffff';
          }
          if(offset >= 278){
              this.number = '02'
              this.titulo = 'About'
              divcont.style.color = 'white';
          }
          if(offset >= 355){
              divcont.style.opacity = 1;
              
          }
          if(offset >= 769){
              divcont.style.opacity = 0;
              
          }
          if(offset >= 1027){
              this.number = '03'
              this.titulo = 'Our services'
              divcont.style.color = 'white';
          }
          if(offset >= 1061){
              divcont.style.opacity = 1;
          }
          if(offset >= 1229){
              divcont.style.opacity = 0;
          }
          if(offset >= 1702){
              this.number = '05'
              this.titulo = 'Magazine'
              divcont.style.color = '#EF67C2';
              oa.style.color = '#EF67C2';
          }
          if(offset >= 2189){
              divcont.style.opacity = 1;
          }
          if(offset >= 2281){
              divcont.style.opacity = 0;
          }
          
          if(offset <= 2294){
            divcont2.style.opacity = 1;
          }
          if(offset >= 2395){
            divcont2.style.opacity = 0;
          }

          if(offset >= 2335){
              this.number = ''
              this.titulo = 'Let´s talk about your project.'
              divcont.style.color = '#EF67C2';
              oa.style.color = '#EF67C2';
          }
          if(offset <= 2334){
              divcont.style.width = '100%';
              divcont2.style.backgroundImage = 'none';
          }
          if(offset >= 2335){
              divcont.style.width = '68%';
              divcont2.style.backgroundImage = 'url('+require('../../assets/dg-1.png')+')';
          }

          
          /*if(offset <= 2219){2328
              divcont2.style.background = 'transparent';
          }*/
          /*
          if(offset <= 2394){
            divcont2.style.opacity = 0;
          }*/

          if(offset >= 2395){
            divcont2.style.opacity = 1;
              divcont.style.opacity = 1;
          }
          if(offset <= 2497){
              divcont2.style.backgroundSize = '100% 45%';
          }
          if(offset >= 2498){
              divcont2.style.backgroundSize = '100% 25%';

          }
          if(offset >= 2498){
           //   divcont2.style.backgroundSize = '100% 25%';
              divcont.style.opacity = 0;
          }/**/
          
          /**
           * 
           * 
           * 
           * 355.7692307692307 1307.6923076923076 499.99999999999994
           * 2173.076923076923
          */
      }else if(min768.matches){
          if(offset <=  132){
              divcont.style.opacity = 0;
          }
          if(offset >=  132){
              divcont.style.opacity = 1;
              
          }
          if(offset >=  382){
              divcont.style.opacity = 0;
          }
          if(offset <=  1700){
              this.number = '01'
            this.titulo = 'Latest projects.',
            divcont.style.color = '#ef67c2';  
            oa.style.color = '#EF67C2';
          }
          if(offset >=  1700){
              this.number = '03'
              this.titulo = 'Our services'
              divcont.style.color = 'white';
          }
          if(offset >=  1770){
              divcont.style.opacity = 1;
          }
          if(offset >=  2217){
              divcont.style.opacity = 0;
          }
          if(offset <=  3000){
              divcont.style.paddingLeft = 0+'vh';
            oa.style.color = 'white';
          }
          if(offset >=  3000){
              this.number = '05'
              this.titulo = 'Magazine'
              divcont.style.color = '#EF67C2';
            oa.style.color = '#EF67C2';
              divcont.style.paddingLeft = 50+'vh';
          }
          if(offset >=  3298){
              divcont.style.opacity = 1;
          }
          if(offset >=  3509){
              divcont.style.opacity = 0;
          }
          /**3651.6346153846152 */

      }
      
      }
  },
  mounted() {
       window.addEventListener("scroll", this.changeColor);
       window.addEventListener("scroll", this.scrollX);
  },
  destroy() {
     window.removeEventListener("scroll", this.scrollX);
  },
};
</script>
<style scoped>
.pc{
  display: block;
}
.mo{
  display: none;
}
#TituloFijo {
  position: fixed;
  z-index: 501;
  pointer-events: none;
    background-repeat: no-repeat;
    background-size: 100%;
}
.titulo {
  font-family: "Gramatika-Medium";
  font-size: 9.765625vh;
  line-height: 103.8%;
  text-align: center;
  color: #ef67c2;
  margin: 0;
  position: relative;
  z-index: 99;
  left: 0;
  display: flex;
  top: 15.652174vh;
  margin-left: 6vw;
}
.titulo span {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: bold;
  font-size: 1.26953125vh;
  line-height: 103.8%;
  text-align: center;
  color: #ffffff;
  margin-right: 2.19921875vh;
  position: relative;
  top: 1.3vw;
}

.lastProTitulo{
    transition: .5s;
    opacity: 0;
}
@media (max-width: 768px) {
.pc{
  display: none;
}
.mo{
  display: block;
}
  #TituloFijo {
    display: block;
    width: 100%;
    height: 100%;
    transition: .25s;
  }
  .TituloFijo{
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .titulo {
    font-size: 5.541871921182265vh;
    width: 100%;
    text-align: left;
    flex-direction: column;
    margin-left: 9.420vw;
    position: relative;
    top: 16.504347826086957vh;
  }
  .titulo span {
    font-size: 1.6009852216748766vh;
    color: #ffffff;
    width: fit-content;
    margin-bottom: 1.5vh;
  }
}
</style>