<template>
  <div id="LastProjects" class="LastProjects" :style="{ left: 0 }">
    <div class="queue-projects">
      <p class="titulo lastProTitulo"><span>01</span>Latest projects.</p>
      <div class="queue queuePro">
        <div class="project-cont" v-for="(pro, i) in portfolio" :key="i" @mouseover="hoveruno(i + 1)"
          @mouseleave="hoverleave(i + 1)">
          <div class="mobile" v-if="media == 'MO'">
            <div class="bk-pc portfolio-cont-c" :style="{
              'background-image': 'url(' + urlpath.url() + '/project-img/' + pro.imagehome + ')' }">
              <a :href="'/portafolio-post/' + pro.url">
                <div class="text-1">
                  <h1>{{ pro.title }}</h1>
                  <h2>{{ pro.descrp }}</h2>
                  <a :href="'/portafolio-post/' + pro.url" class="seemore">Ver más
                    <img src="../../assets/flecharda.svg" alt="arrow" width="28" height="1" /></a>
                </div>
              </a>
            </div>
          </div>

          <div class="pcitem" v-if="media == 'PC'">
            <div class="bk-pc portfolio-cont-c" :style="{
              'background-image': 'url(' + urlpath.url() + '/project-img/' + pro.imagehome + ')', }">
              <a :href="'/portafolio-post/' + pro.url">
                <div class="text-1">
                  <h1>{{ pro.title }}</h1>
                  <h2>{{ pro.descrp }}</h2>
                  <a :href="'/portafolio-post/' + pro.url" class="seemore">Ver más
                    <img src="../../assets/flecharda.svg" alt="arrow" width="28" height="1" /></a>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="more-projects">
      <!--<a href="/portafolio">
        <div class="conoce-mas-cont">
          <p class="morepro">Conoce más</p>
        </div>
        <img
          src="../../assets/arrow-right.svg"
          alt="arrow"
          width="48"
          height="48"
      />
      </a>-->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
let urlpath = require("../../global/url");

export default {
  data() {
    return {
      urlpath,
      media: 'PC',
      projects: [
        {
          nombre: "Cimarrones de Sonora",
          descripcion: "Desarrollo de sitio web y tienda en linea",
          image: require("../../assets/pro-1.png"),
          link: "/",
        },
        {
          nombre: "Super del Norte",
          descripcion: "Desarrollo de sitio web y tienda en linea",
          image: require("../../assets/pro-2.png"),
          link: "/",
        },
        {
          nombre: "Por Kilo",
          descripcion: "Desarrollo de sitio web y tienda en linea",
          image: require("../../assets/pro-3.png"),
          link: "/",
        },
        {
          nombre: "Sayab",
          descripcion: "Desarrollo de sitio web y tienda en linea",
          image: require("../../assets/pro-4.png"),
          link: "/",
        },
        {
          nombre: "Rojo Siete",
          descripcion: "Desarrollo de sitio web y tienda en linea",
          image: require("../../assets/pro-5.png"),
          link: "/",
        },
      ],
    };
  },
  created() {
    this.getAllInfoPrj("project");
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    portfolio() {
      /***formato */
      let array2 = this.$store.getters["project/data"];
      let catArr = array2.filter(({ formato }) => formato.includes("LARGO"));
      let l1 = catArr.filter(({ orden }) => orden.includes(1));
      let l2 = catArr.filter(({ orden }) => orden.includes(2));
      let l3 = catArr.filter(({ orden }) => orden.includes(3));
      let l4 = catArr.filter(({ orden }) => orden.includes(4));
      let l5 = catArr.filter(({ orden }) => orden.includes(5));

      return [l1[0], l2[0], l3[0], l4[0], l5[0]];
    },
  },
  methods: {
    ...mapActions("project", ["getAllInfoPrj"]),
    /**SLIDE METHODS */
    hoveruno(pos) {
      let mediaq = window.matchMedia("(max-width: 768px)");
      if (!mediaq.matches) {
        if (pos == 1) {
          document.querySelector(
            ".project-cont:nth-child(" + 2 + ")"
          ).style.marginTop = "3.5vh";
          document.querySelector(
            ".project-cont:nth-child(" + 3 + ")"
          ).style.marginTop = "0vw";
          document.querySelector(
            ".project-cont:nth-child(" + 4 + ")"
          ).style.marginTop = "-5vh";
          document.querySelector(
            ".project-cont:nth-child(" + 5 + ")"
          ).style.marginTop = "-1.5vh";
        } else if (pos == 2) {
          document.querySelector(
            ".project-cont:nth-child(" + 3 + ")"
          ).style.marginTop = "3.5vh";
          document.querySelector(
            ".project-cont:nth-child(" + 4 + ")"
          ).style.marginTop = "0vh";
          document.querySelector(
            ".project-cont:nth-child(" + 5 + ")"
          ).style.marginTop = "-3vh";
          document.querySelector(
            ".project-cont:nth-child(" + 1 + ")"
          ).style.marginTop = "-1.5vh";
        } else if (pos == 3) {
          document.querySelector(
            ".project-cont:nth-child(" + 4 + ")"
          ).style.marginTop = "3.5vh";
          document.querySelector(
            ".project-cont:nth-child(" + 5 + ")"
          ).style.marginTop = "0vw";
          document.querySelector(
            ".project-cont:nth-child(" + 1 + ")"
          ).style.marginTop = "-5vh";
          document.querySelector(
            ".project-cont:nth-child(" + 2 + ")"
          ).style.marginTop = "-.5vh";
        } else if (pos == 4) {
          document.querySelector(
            ".project-cont:nth-child(" + 5 + ")"
          ).style.marginTop = "3.5vh";
          document.querySelector(
            ".project-cont:nth-child(" + 1 + ")"
          ).style.marginTop = "0vw";
          document.querySelector(
            ".project-cont:nth-child(" + 2 + ")"
          ).style.marginTop = "-5vh";
          document.querySelector(
            ".project-cont:nth-child(" + 3 + ")"
          ).style.marginTop = "-1.5vh";
        } else if (pos == 5) {
          document.querySelector(
            ".project-cont:nth-child(" + 1 + ")"
          ).style.marginTop = "3.5vh";
          document.querySelector(
            ".project-cont:nth-child(" + 2 + ")"
          ).style.marginTop = "0vw";
          document.querySelector(
            ".project-cont:nth-child(" + 3 + ")"
          ).style.marginTop = "-5vh";
          document.querySelector(
            ".project-cont:nth-child(" + 4 + ")"
          ).style.marginTop = "-1.5vh";
        }
      }
    },
    hoverleave(pos) {
      let mediaq = window.matchMedia("(max-width: 768px)");
      if (!mediaq.matches) {
        if (pos == 1) {
          document.querySelector(
            ".project-cont:nth-child(" + 2 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 3 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 4 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 5 + ")"
          ).style.marginTop = "0";
        } else if (pos == 2) {
          document.querySelector(
            ".project-cont:nth-child(" + 3 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 4 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 5 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 1 + ")"
          ).style.marginTop = "0";
        } else if (pos == 3) {
          document.querySelector(
            ".project-cont:nth-child(" + 4 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 5 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 1 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 2 + ")"
          ).style.marginTop = "0";
        } else if (pos == 4) {
          document.querySelector(
            ".project-cont:nth-child(" + 5 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 1 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 2 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 3 + ")"
          ).style.marginTop = "0";
        } else if (pos == 5) {
          document.querySelector(
            ".project-cont:nth-child(" + 1 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 2 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 3 + ")"
          ).style.marginTop = "0";
          document.querySelector(
            ".project-cont:nth-child(" + 4 + ")"
          ).style.marginTop = "0";
        }
      }
    },
    mediaQuery() {
      let min768 = window.matchMedia("(min-width: 768px)");
      let media768 = window.matchMedia("(max-width: 768px)");

      if (media768.matches) {
        this.media = 'MO'
      }
      else if (min768.matches) {
        this.media = 'PC'
      }
    },
  },
  mounted() {
    window.addEventListener("load", this.mediaQuery);
  },
};
</script>
<style scoped>
#LastProjects {
  width: fit-content;
  height: 100vh;
  display: flex;
  align-items: center;
  cursor: default;
  padding-left: 9.5vh;
  position: relative;
  /** transition:  .3s cubic-bezier(0.1, 0.1, 1, 1); ease-out; */
  transition: 0.3s ease-out;
}

.queue {
  display: flex;
  width: fit-content;
  justify-content: space-between;
  position: relative;
  transition: 0.5s;
}

.bk-pc {
  background-color: #eae8ff;
  background-position-y: center;
  background-position-x: 0;
  background-size: 100%;
  background-repeat: no-repeat;
}

.project-cont {
  background-size: 64.16015625vh;
  background-repeat: no-repeat;
  transition: 1s;
  background-position-x: center;
  background-position-y: top;
  width: 30.76171875vh;
  height: 64.16015625vh;
  margin-right: 1.5vh;
  cursor: none;
  position: relative;
}

.portfolio-cont-c {
  cursor: none;
}

.project-cont:hover {
  /*width: 64.16015625vh;*/
  height: 64.16015625vh;
  background-size: 71.160156vh;
}

.text-1 {
  opacity: 0;
  transition: 0.5s;
  height: 59.860156vh;
  padding-left: 4.4921875vh;
  padding-right: 4.4921875vh;
  padding-bottom: 4.4921875vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.project-cont:hover .text-1 {
  opacity: 1;
  background-color: #000000a3;
  /* cursor: url("../../assets/cursor.svg"), auto;*/
}

.text-1 h1,
.text-1 h2,
.text-1 a.seemore {
  margin: 0;
}

.text-1 h1 {
  font-family: "Gramatika-Black";
  font-size: 2.9296875vh;
  line-height: 103.8%;
  letter-spacing: 0;
  color: #ffffff;
  transition: 1s;
  margin-bottom: 1vw;
}

.project-cont:hover .text-1 h1 {
  letter-spacing: 0;
}

.text-1 h2 {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3671875vh;
  line-height: 135.8%;
  letter-spacing: 0;
  color: #ffffff;
  transition: 0.5s;
  width: 23.92578125vh;
  margin-bottom: 3vw;
}

.project-cont:hover .text-1 h2 {
  letter-spacing: 0;
}

.more-projects {
  /*margin-left: 8vh;*/

  margin-left: 23vh;
  margin-right: 17.96875vh;
}

.more-projects a {
  display: flex;
  width: 23vh;
  justify-content: space-between;
  align-items: center;
}

.more-projects p {
  font-family: "Gramatika-Black";
  font-size: 1.9704433497536944vh;
  line-height: 127.3%;
  text-align: center;
  color: #b2acfc;
  margin: 0;
  transition: 0.5s;
}

.more-projects:hover p {
  color: #ffffff;
}

.flecha-ar {
  width: 4.6875vh;
  height: 4.6875vh;
  background-size: 4.6875vh;
  background-image: url("../../assets/arrow-right.svg");
  transition: 0.5s;
}

.more-projects:hover .flecha-ar {
  background-image: url("../../assets/btn-pink.svg");
}

.more-projects img {
  transition: 0.5s;
}

.more-projects:hover img {
  filter: saturate(3.5);
}

.text-1 a.seemore {
  font-family: "Gramatika-Black";
  font-size: 1.171875vh;
  line-height: 127.3%;
  color: #ffffff;
  text-decoration-color: transparent;
}

.text-1 a.seemore img {
  width: 2.734375vh;
  height: auto;
  margin-left: 1vh;
}

.titulo {
  display: none;
  font-family: "Gramatika-Medium";
  font-size: 9.765625vh;
  line-height: 103.8%;
  text-align: center;
  color: #ef67c2;
  margin: 0;
  position: relative;
  z-index: 99;
  left: 0;
  top: 1vw;
  margin-left: 6vw;
}

.titulo span {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: bold;
  font-size: 1.26953125vh;
  line-height: 103.8%;
  text-align: center;
  color: #ffffff;
  margin-right: 2.19921875vh;
  position: relative;
  top: 1.3vw;
}

.conoce-mas-cont p {
  font-family: "Gramatika-Bold";
  font-size: 1.042vw;
  line-height: 152.3%;
  text-align: center;
  color: #ef67c2;
}

/*.project-cont:nth-child(1):hover ~ .project-cont:nth-child(2),
.project-cont:nth-child(2):hover ~ .project-cont:nth-child(3),
.project-cont:nth-child(3):hover ~ .project-cont:nth-child(4),
.project-cont:nth-child(4):hover ~ .project-cont:nth-child(5),
.project-cont:nth-child(5):hover > .project-cont:nth-child(1){
  margin-top: 1.5vw;
}
.project-cont:nth-child(1):hover ~ .project-cont:nth-child(3),
.project-cont:nth-child(2):hover ~ .project-cont:nth-child(4),
.project-cont:nth-child(3):hover ~ .project-cont:nth-child(5),
.project-cont:nth-child(4):hover ~ .project-cont:nth-child(1),
.project-cont:nth-child(5):hover ~ .project-cont:nth-child(2){
  margin-top: 0;
}
.project-cont:nth-child(1):hover ~ .project-cont:nth-child(4),
.project-cont:nth-child(2):hover ~ .project-cont:nth-child(5),
.project-cont:nth-child(3):hover + .project-cont:nth-child(1),
.project-cont:nth-child(4):hover + .project-cont:nth-child(2),
.project-cont:nth-child(5):hover + .project-cont:nth-child(3){
  margin-top: -3vw;
}*/

/*.project-cont:nth-child(2) {
  width: 30.76171875vh;
  height: 64.16015625vh;
}
.project-cont:nth-child(3) {
  width: 30.76171875vh;
  height: 64.16015625vh;
}
.project-cont:nth-child(4) {
  width: 30.76171875vh;
  height: 64.16015625vh;
}
.project-cont:nth-child(5) {
  width: 30.76171875vh;
  height: 64.16015625vh;
}*/
.mobile {
  display: none;
}

.pcitem {
  display: block;
}

@media (max-width: 768px) {
  .mobile {
    display: block;
  }

  .pcitem {
    display: none;
  }

  .titulo {
    font-size: 5.541871921182265vh;
    width: 100%;
    text-align: left;
    flex-direction: column;
    transition: 0.25s;
  }

  .titulo span {
    font-size: 1.6009852216748766vh;
    color: #fff;
    width: fit-content;
  }

  .project-cont {
    width: 40.39408866995073vh;
    height: 65.88669950738915vh;
  }

  .text-1 {
    height: 61.64532vh;
  }

  .project-cont:hover {
    width: 40.39408866995073vh;
    height: 65.88669950738915vh;
    background-size: 71.160156vh;
  }

  .project-cont:nth-child(2) {
    margin-top: 0 !important;
  }

  .project-cont:nth-child(3) {
    margin-top: 0 !important;
  }

  .project-cont:nth-child(4) {
    margin-top: 0 !important;
  }

  .project-cont:nth-child(5) {
    margin-top: 0 !important;
  }

  .more-projects {
    margin-left: 25.96875vh;
    margin-right: 25.96875vh;
    transition: 0.5s;
  }

  /* Ajustes movil LG */
  .titulo span {
    color: #ffffff !important;
    font-size: 3.140096618357488vw !important;
  }
}
</style>