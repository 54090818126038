<template>
  <div id="FixedItems">
    <div class="circle-part">
        <div class="spin" @click="webLoad('inicio')">
          <div class="font-cont pc">
            <p class="font">
              <span class="uno">Scroll </span>
              <span class="due">Next </span>
            </p>
            >
          </div>
          <div class="font-cont mo">
            <p class="font">
              <span class="uno">Scroll </span>
              <span class="due">Next </span>
            </p>
            ▼
          </div>
          <div class="circulo">
            <div class="rotate">
              <svg height="200" width="200">
                <circle
                  class="circle"
                  cx="100"
                  cy="100"
                  r="82"
                  fill="white"
                  stroke="#ffffff"
                  stroke-width="1"
                  fill-opacity="1"
                />
              </svg>
            </div>
            <div class="circlediv">
              <svg height="200" width="200">
                <circle
                  class="circle-f"
                  cx="100"
                  cy="100"
                  r="82"
                  stroke="#ffffff"
                  stroke-width="1"
                  fill-opacity="0"
                />
              </svg>
            </div>
          </div>
        </div>
    </div>
    <div class="sm-cont">
      <a
        href="https://es-la.facebook.com/legrafica/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src="../../assets/icon-fb.svg"
          alt="sm"
          class="icon"
          width="8"
          height="14"
        />
      </a>
      <a
        href="https://www.instagram.com/legrafica/?hl=es"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src="../../assets/icon-ig.svg"
          alt="sm"
          class="icon"
          width="8"
          height="14"
        />
      </a>
      <a
        href="https://twitter.com/legrafica"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src="../../assets/icon-tw.svg"
          alt="sm"
          class="icon"
          width="8"
          height="14"
        />
      </a>
      <a
        href="https://mx.linkedin.com/company/legrafica"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src="../../assets/icon-li.svg"
          alt="sm"
          class="icon"
          width="8"
          height="14"
        />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      web: "",
    };
  },
  methods: {
    routeChange(event){
      let min768 = window.matchMedia("(min-width: 768px)");
      let media768 = window.matchMedia("(max-width: 768px)");
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;/**/
 
      if (media768.matches) {
        if(offset < 47){
          this.$router.push("/").catch(()=>{});
        }
        if(offset > 48 && offset < 365){
          this.$router.push("/home/latest-proyects").catch(()=>{});
        }
        if(offset > 365 && offset < 573){
          this.$router.push("/home/about").catch(()=>{});
        }
        if(offset > 573 && offset < 839){
          this.$router.push("/home/team").catch(()=>{});
        }
        if(offset > 839 && offset < 911){
          this.$router.push("/home/stories").catch(()=>{});
        }
        if(offset > 911 && offset < 1665){
          this.$router.push("/home/services").catch(()=>{});
        }
        if(offset > 1665 && offset < 2203){
          this.$router.push("/home/partners").catch(()=>{});
        }
        if(offset > 2203 && offset < 2401){
          this.$router.push("/home/magazine").catch(()=>{});
        }
        if(offset > 2401 && offset < 2553){
          this.$router.push("/home/contact-us").catch(()=>{});
        }
      }
      else if (min768.matches) 
      {
        if(offset < 230){
          this.$router.push("/").catch(()=>{});
        }
        if(offset > 230 && offset < 480){
          this.$router.push({ path: '/home/latest-proyects' }).catch(()=>{});
        }
        if(offset > 480 && offset < 798){
          this.$router.push("/home/about").catch(()=>{});
        }
        if(offset > 798 && offset < 1163){
          this.$router.push("/home/team").catch(()=>{});
        }
        if(offset > 1163 && offset < 1346){
          this.$router.push("/home/stories").catch(()=>{});
        }
        if(offset > 1346 && offset < 2203){
          this.$router.push("/home/services").catch(()=>{});
        }
        if(offset > 2203 && offset < 3326){
          this.$router.push("/home/partners").catch(()=>{});
        }
        if(offset > 3326 && offset < 3798){
          this.$router.push("/home/magazine").catch(()=>{});
        }
        if(offset > 3798 && offset < 3976){
          this.$router.push("/home/contact-us").catch(()=>{});
        }
      }     
    },
    webLoad(name) {
      let min768 = window.matchMedia("(min-width: 768px)");
      let media768 = window.matchMedia("(max-width: 768px)");


      if (media768.matches) {
      if (name == 'inicio') {
        this.$router.push("/home/latest-proyects");
        window.scrollTo(0,564)
      } 
       if(this.$route.name == 'latest-proyects'){
        window.scrollTo(0,3874)
        this.$router.push("/home/about");
      }
       if(this.$route.name == 'about'){
        window.scrollTo(0,5963)
        this.$router.push("/home/team");
      }
       if(this.$router.name == 'team'){
        window.scrollTo(0,8731)
        this.$router.push("/home/stories");
      }
       if(this.$route.name == 'stories'){
        window.scrollTo(0,9475)
        this.$router.push("/home/services");
      }
       if(this.$route.name == 'services'){
        window.scrollTo(0,17441)
        this.$router.push("/home/partners");
      }
       if(this.$route.name == 'partners'){
        window.scrollTo(0,22920)
        this.$router.push("/home/magazine");
      }
       if(this.$route.name == 'magazine'){
        window.scrollTo(0,24975)
        this.$router.push("/home/contact-us");
      }
       if(this.$route.name == 'contact-us'){
        window.scrollTo(0,0)
        this.$router.push("/");
      }
}
else if (min768.matches) 
{
      if (name == "inicio") {
        this.$router.push("/home/latest-proyects");
        window.scrollTo(0,2402)
      } 
       if(this.$route.name == 'latest-proyects'){
        window.scrollTo(0,4875)
        this.$router.push("/home/about");
      }
      if(this.$route.name == 'about'){
        window.scrollTo(0,8365)
        this.$router.push("/home/team");
      }
      if(this.$route.name == 'team'){
        window.scrollTo(0,12436)
        this.$router.push("/home/stories");
      }
      if(this.$route.name == 'stories'){
        window.scrollTo(0,14136)
        this.$router.push("/home/services");
      }
      if(this.$route.name == 'services'){
        window.scrollTo(0,30836)
        this.$router.push("/home/partners");
      }
      if(this.$route.name == 'partners'){
        window.scrollTo(0,34736)
        this.$router.push("/home/magazine");
      }
      if(this.$route.name == 'magazine'){
        window.scrollTo(0,40236)
        this.$router.push("/home/contact-us");
      }
      if(this.$route.name == 'contact-us'){
        window.scrollTo(0,0)
        this.$router.push("/");
      }
  }
      /***
        if(this.$route.params.name == 'about'){

        }         */
    },

  },
  mounted() {
     window.addEventListener("scroll", this.routeChange);/**/
  },
};
</script>
<style scoped>
#FixedItems {
  position: fixed;
  bottom: 4.8828125vh;
  left: 3.41796875vh;
  z-index: 500;
}
.pc{
  display: flex!important;
}
.mo{
  display: none!important;
}
/**CIRCLE */
.spin {
  border: 0;
  position: relative;
  /* background-color: #b1acfc7e;*/
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 16vh;
  width: 18vh;
  transition: 0.5s;
  overflow: hidden;
}
.circulo {
  transform: rotate(270deg);
}
.circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  r: 7.78125vh !important;
  stroke: rgb(255, 255, 255);
  stroke-width: 0.19765625vh;
  transition: 0.5s;
  fill: #b1acfc7e !important;
}
.circle-f {
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  r: 7.78125vh !important;
  stroke: rgba(255, 255, 255, 0.494) !important;
  stroke-width: 0.09765625vh;
}
.circlediv {
  position: absolute;
  top: 0;
}

.spin:hover .circle {
  animation: stroke 5s ease-out forwards infinite;
}

.font-cont {
  display: flex;
  justify-content: center;
  width: 4vh;
  align-items: center;
  position: absolute;
  font-family: "Gramatika-Medium";
  font-size: 2.24609375vh;
  line-height: 2.24609375vh;
  color: #ffffff;
  z-index: 9;
}
.font {
  display: flex;
  flex-direction: column;
  letter-spacing: 0;
  animation-name: spacingwords;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 3s;
  opacity: 1;
  margin-right: 0.3vw;
  max-height: 3vw;
  margin: 0;
}

.spin:hover .font,
.spin.circle:hover {
  color: rgb(255, 255, 255);
}
.font .uno {
  max-height: 3vw;
  opacity: 1;
  pointer-events: none;
  transition: 0.5s;
}
.font .due {
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}
.spin:hover .font .due {
  max-height: 3vw;
  opacity: 1;
}
.spin:hover .font .uno {
  max-height: 0;
  opacity: 0;
}
@keyframes stroke {
  25% {
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dashoffset: 0;
  }
  75% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 1000;
  }
}

@keyframes spacingwords {
  0% {
    letter-spacing: 0;
    opacity: 1;
    margin-right: 0.3vw;
  }
  20% {
    letter-spacing: -0.5vw;
    opacity: 0;
    margin-right: 0;
  }
  40% {
    letter-spacing: -0.5vw;
    opacity: 0;
    margin-right: 0;
  }
  60% {
    letter-spacing: 0;
    opacity: 1;
    margin-right: 0.3vw;
  }
  100% {
    letter-spacing: 0;
    opacity: 1;
    margin-right: 0.3vw;
  }
}

/**SOCIAL MEDIA CONT */
.sm-cont {
  width: 14.74609375vh;
  display: flex;
  justify-content: space-between;
  margin-top: 5.76171875vh;
}
.sm-cont img {
  width: auto;
  height: 1.953125vh;
  opacity: 1;
  transition: 0.5s;
}
.sm-cont img:hover {
  opacity: 0.5;
}

@media (max-width: 768px) {
.pc{
  display: none!important;
}
.mo{
  display: flex!important;
}
.font-cont { 
    line-height: 1.46094vh; 
}
@keyframes spacingwords {
  0% {
    letter-spacing: 0;
    opacity: 1;
    margin-right: 1.6vw;
  }
  20% {
    letter-spacing: -2.5vw;
    opacity: 0;
    margin-right: 0;
  }
  40% {
    letter-spacing: -2.5vw;
    opacity: 0;
    margin-right: 0;
  }
  60% {
    letter-spacing: 0;
    opacity: 1;
    margin-right: 1.6vw;
  }
  100% {
    letter-spacing: 0;
    opacity: 1;
    margin-right: 1.6vw;
  }
}
}
</style>