<template>
  <!--:style="{'background-image':'url('+isWebp(require('../../assets/fondiu-2.webp'), require('../../assets/fondiu-2.png'))+')'}"-->
  <div id="PartnersC" class="PartnersC">
    <div class="scrollx">
      <!--<div
        class="sticky-div"
        :class="{
          fondoiguana: isWebp(true, false),
          fondoiguana2: isWebp(false, true),
        }"
      >
      </div>-->
      <img src="../../assets/iguana-bailada.png" alt="iguana" class="sticky-div fondoiguana">
      <div class="text-par-c">
        <p class="tpc-text">
          <span class="conect">Conectamos a las personas</span>
          <span class="merchtxt">con tu marca</span>
        </p>
        </div>
    </div>
    <div class="partners-s-c" id="Partners">
      <div class="titulo-psc">
        <p class="titulo"><span>04</span>Partners.</p>
      </div>
      <div class="p-slider-sec">
        <div class="gradientP mo"></div>
        <div class="partners-scrolled">
          <div class="list-bar partnerList" id="partnerList">
            <div
              class="column-sl uno"
              v-for="(ps, index) in partnersSlide"
              :key="index"
            >
              <a :href="ps.link1">
                <img :src="ps.image1" alt="logo" class="logo-ps">
              <!--<div
                  class="logo-ps"
                  :style="{ 'background-image': 'url(' + ps.image1 + ')' }"
                ></div>-->  
              </a>
              <a :href="ps.link1">
                <img :src="ps.image2" alt="logo" class="logo-ps">
                <!--<div
                  class="logo-ps"
                  :style="{ 'background-image': 'url(' + ps.image2 + ')' }"
                ></div>-->
              </a>
              <!--
                    @mouseover="hoverLogo(ps.image2h,index)"
                    @mouseleave="hoverLogo(ps.image2,index)"
              -->
            </div>
          </div>
        </div>
        <div class="slider-container">
          <div class="carrousel-slide carSlideCont1">
            <div
              class="column-sl due"
              v-for="(ps, index) in partnersSlide1"
              :key="index"
            >
              <a :href="ps.link1">
                <div
                  class="logo-ps"
                  :style="{ 'background-image': 'url(' + ps.image1 + ')' }"
                ></div>
              </a>
              <a :href="ps.link1">
                <div
                  class="logo-ps"
                  :style="{ 'background-image': 'url(' + ps.image2 + ')' }"
                ></div>
              </a>
              <!--
                    @mouseover="hoverLogo(ps.image2h,index)"
                    @mouseleave="hoverLogo(ps.image2,index)"
                    -->
            </div>
          </div>
          <div class="carrousel-slide carSlideCont2">
            <div
              class="column-sl tre"
              v-for="(ps, index) in partnersSlide2"
              :key="index"
            >
              <a :href="ps.link1">
                <div
                  class="logo-ps"
                  :style="{ 'background-image': 'url(' + ps.image1 + ')' }"
                ></div>
              </a>
              <a :href="ps.link1">
                <div
                  class="logo-ps"
                  :style="{ 'background-image': 'url(' + ps.image2 + ')' }"
                ></div>
              </a>
              <!--
                    @mouseover="hoverLogo(ps.image2h,index)"
                    @mouseleave="hoverLogo(ps.image2,index)"
                    -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="acru">
      <!--<div class="rose-div mo"></div>-->
      
      <video class="video-head videoPartners v-pc" loop autoplay muted>
        <source src="../../assets/services.mp4" type="video/mp4" />
      </video>
      
      <video class="video-head videoPartners v-mo" loop autoplay muted>
        <source src="../../assets/services-1.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</template>
<script>
import { VueAgile } from "vue-agile";
export default {
  data() {
    return {
      partnersSlide: [
        {
          link1: "/",
          image1: require("../../assets/partners/Agrofesa.png"),
          image1h: require("../../assets/partners/Agrofesa_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/Cedasa.png"),
          image2h: require("../../assets/partners/Cedasa_hover.png"),
        },
        {
          link1: "/",
          image1: require("../../assets/partners/BAMX.png"),
          image1h: require("../../assets/partners/BAMX_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/Circulo.png"),
          image2h: require("../../assets/partners/Circulo_hover.png"),
        },
        {
          link1: "/",
          image1: require("../../assets/partners/Bioderma.png"),
          image1h: require("../../assets/partners/Bioderma_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/Fanosa.png"),
          image2h: require("../../assets/partners/Fanosa_hover.png"),
        },
        {
          link1: "/",
          image1: require("../../assets/partners/Cimarrones.png"),
          image1h: require("../../assets/partners/Cimarrones_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/FA.png"),
          image2h: require("../../assets/partners/FA_hover.png"),
        },

        {
          link1: "/",
          image1: require("../../assets/partners/HSB.png"),
          image1h: require("../../assets/partners/HSB_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/Make.png"),
          image2h: require("../../assets/partners/Make_hover.png"),
        },
        {
          link1: "/",
          image1: require("../../assets/partners/PorKilo.png"),
          image1h: require("../../assets/partners/PorKilo_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/Sayab.png"),
          image2h: require("../../assets/partners/Sayab_hover.png"),
        },
        {
          link1: "/",
          image1: require("../../assets/partners/SDN1.png"),
          image1h: require("../../assets/partners/SDN_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/Symborg.png"),
          image2h: require("../../assets/partners/Symborg_hover.png"),
        },

        {
          link1: "/",
          image1: require("../../assets/partners/VM.png"),
          image1h: require("../../assets/partners/VM_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/Virgo.png"),
          image2h: require("../../assets/partners/Virgo.png"),
        },

        {
          link1: "/",
          image1: require("../../assets/partners/Pipeso.png"),
          image1h: require("../../assets/partners/Pipeso.png"),
          link2: "/",
          image2: require("../../assets/partners/Bistro.png"),
          image2h: require("../../assets/partners/Bistro.png"),
        },
        /*{
          link1: "/",
          image1: require("../../assets/partners/Bioderma.png"),
          image1h: require("../../assets/partners/Bioderma_hover.png"),
        },*/
      ],
      partnersSlide1: [
        {
          link1: "/",
          image1: require("../../assets/partners/Fanosa.png"),
          image1h: require("../../assets/partners/Fanosa_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/VM.png"),
          image2h: require("../../assets/partners/VM_hover.png"),
        },
        {
          link1: "/",
          image1: require("../../assets/partners/SDN.png"),
          image1h: require("../../assets/partners/SDN_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/BAMX.png"),
          image2h: require("../../assets/partners/BAMX_hover.png"),
        },
        {
          link1: "/",
          image1: require("../../assets/partners/Cimarrones.png"),
          image1h: require("../../assets/partners/Cimarrones_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/FA.png"),
          image2h: require("../../assets/partners/FA_hover.png"),
        },
        {
          link1: "/",
          image1: require("../../assets/partners/Circulo.png"),
          image1h: require("../../assets/partners/Circulo_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/PorKilo.png"),
          image2h: require("../../assets/partners/PorKilo_hover.png"),
        },
      ],
      partnersSlide2: [
        {
          link1: "/",
          image1: require("../../assets/partners/Agrofesa.png"),
          image1h: require("../../assets/partners/Agrofesa_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/Cedasa.png"),
          image2h: require("../../assets/partners/Cedasa_hover.png"),
        },
        {
          link1: "/",
          image1: require("../../assets/partners/Symborg.png"),
          image1h: require("../../assets/partners/Symborg_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/Bioderma.png"),
          image2h: require("../../assets/partners/Bioderma_hover.png"),
        },
        {
          link1: "/",
          image1: require("../../assets/partners/Make.png"),
          image1h: require("../../assets/partners/Make_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/Sayab.png"),
          image2h: require("../../assets/partners/Sayab_hover.png"),
        },
        {
          link1: "/",
          image1: require("../../assets/partners/Bioderma.png"),
          image1h: require("../../assets/partners/Bioderma_hover.png"),
          link2: "/",
          image2: require("../../assets/partners/HSB.png"),
          image2h: require("../../assets/partners/HSB_hover.png"),
        },
      ],
      options: {
        infinite: true,
        slidesToShow: 4,
        navButtons: true,
        dots: false,
      },
    };
  },
  components: {
    agile: VueAgile,
  },
  methods: {
    hoverLogo(image, index) {
      let divimage = document.getElementsByClassName("logo-ps")[index];
      divimage.style.backgroundImage = "url(" + image + ")";
    },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    },
    slidePartners(event) {
      let divcont = document.getElementsByClassName("carrousel-slide")[0];
      let min768 = window.matchMedia("(min-width: 768px)");
      let media768 = window.matchMedia("(max-width: 768px)");
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
    },
    iscroll(event) {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let b1 = offset - 1250;
      let dc4 = document.getElementsByClassName("partnerList")[0];
      //1754
      //console.log('fate '+fate) 2105 1346.153846153846 -1368.38vh merchtxt
      if (media768.matches) {
        if (offset < 1656) {
          dc4.style.top = 0 + "vh";
          dc4.style.opacity = 1;
        }
        else if (offset > 1657 && offset < 1858) {
          dc4.style.top = 0-b1 + "vh";
          dc4.style.opacity = 0;
        }
        if (offset > 1859) {
          dc4.style.top = -60.8192 + "vh";
        }
      } else if(min768.matches) {

      }
    },
  },
  mounted() {
    window.removeEventListener("scroll", this.iscroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.iscroll);
  },
};
</script>
<style scoped>
.mo{
  display: none;
}
.v-pc{
  display: block;
}
.v-mo{
  display: none;
}
div#PartnersC {
  display: flex;
  background-color: #ef67c2;
  transition: 1s;
  height: 100%;
}
.conect {
  /*opacity: 0;*/
  color: rgb(255 255 255 / 0);
  pointer-events: none;
  transition: 0.5s;
}
.merchtxt{
  /*opacity: 0;*/
  color: rgb(255 255 255 / 0);
  pointer-events: none;  
  transition: 0.5s;
  padding-left: 1.4vh;
}
.carSlideCont1,
.carSlideCont2 {
  transition: 0.5s !important;
}
.carSlideCont2 {
  top: -40vh;
}
/*.fondoiguana {
  background-image: url("../../assets/iguana-bailada.png");
  background-size: 113.4765625vh;
  background-repeat: no-repeat;
  background-position-x: center;
  background-origin: content-box;
  transition: 0.25s ease-out;
}
.fondoiguana2 {
  background-image: url("../../assets/iguana-bailada.png");
  background-size: 113.4765625vh;
  background-repeat: no-repeat;
  background-position-x: center;
  background-origin: content-box;
}*/
.scrollx {
  width: 187.5vh;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
}
.partners-s-c {
  position: relative;
  transition: 0.5s;
}
.sticky-div {
  height: 99.8046875vh;
  /*height: auto;*/
  /*width: 100%;
  width: 103.7109375vh;*/
  width: 108.710938vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-par-c {
 /* opacity: 0;
  pointer-events: none;*/
    position: absolute;
    top: 46.19140625vh;
    /*left: 29.792vw;*/
    left: 22.792vw;
}
.text-par-c p {
  font-family: Gramatika-Medium;
  font-size: 5.859375vh;
  line-height: 109.3%;
  color: rgb(255 255 255 / 0%);/**/
  margin: 0;
  position: relative;
  z-index: 200;
}
.pink {
  color: #ffb6ea;
  transition: 0.5s;
  margin-left: 2vw;
}
#Partners {
  background-size: cover;
  background-position-x: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*padding: 0 21.58203125vh;*/
  padding: 0 65.58203125vh;
}
.titulo-psc {
  position: relative;
  transition: 0.25s ease-out;
}
.titulo-psc p.titulo {
  font-family: Gramatika-Medium;
  font-size: 9.765625vh;
  line-height: 9.765625vh;
  letter-spacing: -0.015em;
  color: #ffb6ea;
  margin: 0;
  z-index: 100;
  position: relative;
  display: flex;
}
.titulo-psc p span {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 1.26953125vh;
  line-height: 1.26953125vh;
  width: 1.2vw;
  position: relative;
  top: 1.2vw;
}
.logo-cont {
  width: 108.30078125vh;
  height: auto;
}
.slider-container {
  width: 130vh;
  height: 42vh;
  display: block;
  overflow: hidden;
}
.partners-scrolled {
  display: none;
}
.acru .video-head {
  width: 50vh;
  height: 100%;
  position: relative;
  z-index: 99;
}
.logo-ps {
  width: 19vh;
  height: 11vh;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: center;
  margin: auto;
  transition: 0.5s;
}
.logo-ps:hover {
  opacity: 0.6;
}
.carrousel-slide {
  display: flex;
  width: fit-content;
  justify-content: space-between;
  position: relative;
  transition: 0.05s;
}
.column-sl {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 33vh;
  height: 39vh;
  background-image: url("../../assets/linea-slider.svg");
  background-size: 1vh 39vh;
  background-repeat: no-repeat;
  background-position-x: right;
  /* border-right: 1px solid white;*/
}
.p-slider-sec {
  margin-top: 20vh;
}

  .list-bar,.partnerList{
    position: relative;
  }.partnerList{
    transition-timing-function: linear;
  }
@media (max-width: 768px) {
.mo{
  display: block;
}
.v-pc{
  display: none;
}
.v-mo{
  display: block;
}
  .acru {
    background-position-x: right;
    background-size: cover;
    overflow: hidden;
  }
  @media (max-height:668px) {
  .acru .video-head {
    transition: 1s;
   /* width: 63.25vh;*/
    width: 125.25vw;
    object-fit: cover;
    position: relative;
  }
.rose-div {
    position: absolute;
    z-index: 100;
    height: 100vh;
   /* width: 63.25vh;*/
    width: 125.25vw;
    pointer-events: none;
    background: rgba(239,103,194,1);
}
    
  }
  @media (min-height:668px) {
  .acru .video-head {
    transition: 1s;
    width: 71.25vh;
    /*width: 135.25vw;*/
    object-fit: cover;
    position: relative;
  }
  .rose-div {
      position: absolute;
      z-index: 100;
      height: 100vh;
    width: 63.25vh;
      /* width: 135.25vw;*/
      pointer-events: none;
      background: rgba(239,103,194,1);
  }
  }
  .fondoiguana {
    background-size: 97.96195652173913vh;
    background-position-x: 0vh;
    background-position-y: bottom;
  }
  .sticky-div {
    /*height: 100vh;*/
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .text-par-c {
    position: absolute;
    top: 46.19140625vh;
    /* left: 29.792vw; */
    left: auto;
}
  .text-par-c p {
    font-size: 3.8043478260869565vh;
    width: 47.28260869565217vh;
    text-align: center;
    /*margin-left: 30vh;
    margin-left: 12vh;
    margin-left: 20vh;
    margin-left: 10vh;*/
  }
  .partners-scrolled {
    display: block;
   /* height: 40.21739130434782vh;*/
    height: 55.217391vh;
    overflow: hidden;
    margin-left: 0;
  }
  @media (min-height: 850px) {
  .text-par-c p {
    margin-left: 3vh;
  }
  .partners-scrolled {
    width: 38vh;
  }
  }
  @media (max-height: 850px) {
  .text-par-c p {
    margin-left: 7vh;
  }
  .partners-scrolled {
    width: 44vh;
  }
  }
  .scrollx {
    width: 147.5vh;
    /*width: 58.5vh;*/
  }
  .titulo-psc p.titulo {
    font-size: 6.114130434782608vh;
    line-height: 10.11413vh;
  }
  .titulo-psc p span {
    font-size: 1.766304347826087vh;
    line-height: 1.766304347826087vh;
  }
  .slider-container {
    display: none;
  }
  /*.list-bar {
    position: relative;
  }*/
  .column-sl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: fit-content;
    border-right: none;
    background-image: none;
    width: auto;
  }
  .logo-ps {
    min-width: 8.831521739130434vh;
    width: 18.070652173913043vh;
    /*max-width: 18.342391304347824vh;*/
    min-height: 6.1141vh;
    height: auto;
    /*max-height: 9vh;*/
    background-repeat: no-repeat;
    background-size: auto 8.4239vh;
    background-position-x: center;
    background-position-y: center;
    margin: 0 auto 6.657608695652174vh;
    transition: 0.5s;
  }
  #Partners {
    /*width: 170vh;*/
    width: 60vh;
  padding: 0 5.58203125vh 0 65.58203125vh;
  }
.p-slider-sec {
  margin-top: 4vh;
}
.gradientP{
  position: absolute;
  height: 10vh;
  width: 48vh;
  background: linear-gradient(180deg, rgba(239,103,194,1) 38%, rgba(239,103,194,0) 90%);
  z-index: 99;
}
}
</style>