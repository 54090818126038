<template>
  <div id="Integrantes" class="integrantes" ref="integrantes">
    <div class="integrantes-cont" id="integrantes-c" style="left: 1%">
      <div class="frame frameUno teamateFr" to="/portafolio">
        <div :style="{
          'background-image':
            'url(' + require('../../assets/frame-1.png') + ')',
        }" alt="image" class="imagen-frame" width="402" height="599" />
      </div>
      <div class="frame frameTres teamateFr">
        <div :style="{
          'background-image':
            'url(' + require('../../assets/frame-3.png') + ')',
        }" alt="image" class="imagen-frame" width="311" height="599" />
      </div>
      <div class="frame frameCuatro teamateFr">
        <a href="/about" target="_blank" ref="noreferrer noopener">
          <div :style="{
            'background-image':
              'url(' + require('../../assets/frame-4.png') + ')',
          }" alt="image" class="imagen-frame" width="621" height="599" />
        </a>
        <div class="btn-cont">
          <!--<div class="more-projects pc">
            <a href="/about">
              <p>Conoce más aquí</p>
              <div class="flecha-ar"></div>
              <img
                src="../../assets/arrow-right.svg"
                alt="arrow"
                width="48"
                height="48"
              />
            </a>
          </div>-->
        </div>
        <!--</a>
        <img
          src="../../assets/frame-4.png"
          alt="image"
          class="imagen-frame"
          width="621"
          height="599"
        />-->
      </div>
      <div class="frame frameCuatro teamateFr">
        <a href="/about" target="_blank" ref="noreferrer noopener">
          <div :style="{
            'background-image':
              'url(' + require('../../assets/frame-5.png') + ')',
          }" alt="image" class="imagen-frame" width="621" height="599" />
        </a>
        <div class="btn-cont">
          <!--<div class="more-projects pc">
            <a href="/about">
              <p>Conoce más aquí</p>
              <div class="flecha-ar"></div>
              <img
                src="../../assets/arrow-right.svg"
                alt="arrow"
                width="48"
                height="48"
              />
            </a>
          </div>-->
        </div>
        <!--</a>
        <img
          src="../../assets/frame-4.png"
          alt="image"
          class="imagen-frame"
          width="621"
          height="599"
        />-->
      </div>
      <div class="more-projects mo">
        <a href="/about">
          <div class="circulo-vm">
            <p>Conoce más</p>
          </div>
        </a>
      </div>
    </div>
    <div class="lr-cont">
      <div class="letras">
        <div class="sticky-l">
          <p id="wuno" class="wuno">We'll</p>
          <div class="l-pc">
            <p id="wdos" class="wdos">always find</p>
            <p id="wtres" class="pink wtres">creativity</p>
          </div>
          <div class="l-mo">
            <p id="wdos" class="wdosp">always</p>
            <p id="wtres" class="wtresp">find</p>
            <p id="wcuatro" class="wcuatro pink">creativity</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      number: 0,
    };
  },
  props: {
    scrollP: 0,
  },
  methods: {},
  mounted() {
    /* window.addEventListener("wheel", this.intX);*/
  },
  destroy() {
    /* window.removeEventListener("wheel", this.intX);*/
  },
};
</script>
<style scoped>
#Integrantes {
  display: flex;
  background-color: #b2acfc;
  align-items: center;
  height: inherit;
  margin-right: 0;
  position: relative;
  transition: 1s;
}

.wuno,
.wdos,
.wtres,
.wdosp,
.wtresp,
.wcuatro {
  /* transition: .05s;*/
  transition: 0.25s;
  position: relative;
}

.integrantes-cont {
  display: flex;
  column-gap: 3.90625VH;
  position: relative;
  /* transition: .05s;*/
  transition: 0.25s ease-out;
}

.imagen-frame {
  width: 100%;
  height: 100%;
  transition: 1.5s;
  background-size: 100%;
  background-position: center;
}

.imagen-frame:hover {
  background-size: 115%;
}

.frame:nth-child(1) {
  width: 60.64453125VH;
  height: 51.496094vh;
}

.teamateFr,
.teamateFr a {
  cursor: default;
}

.frameCuatro,
.frameCuatro a {
  cursor: none !important;
}

.frame:nth-child(2) {
  width: 30.37109375vh;
  height: 51.496094vh;
}

/* .frame:nth-child(2), */
.frame:nth-child(3) {
  width: 60.64453125VH !important;
  height: 51.496094vh;
}

.frame:nth-child(4) {
  width: 83.59375VH !important;
  height: 51.496094vh;
}

/*.frame:nth-child(4) {
  display: flex;
  justify-content: center;
  transition: 1.5s;
  align-items: center;
  background-image: url("../../assets/frame-4.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}*/
.frame:nth-child(4):hover {
  background-size: 115%;
  background-repeat: no-repeat;
}

/*.frame:nth-child(4) .imagen-frame {
  filter: saturate(0);
  opacity: 0.7;
}*/
/*.frame:nth-child(4):hover .imagen-frame {
  filter: saturate(0);
}*/
.btn-cont {
  z-index: 29;
}

.more-projects {
  margin-left: 0;
  margin-right: 0;
}

.more-projects.pc {
  display: block;
}

.more-projects.mo {
  display: none;
}

.more-projects a {
  display: flex;
  width: 23vh;
  justify-content: space-between;
  align-items: center;
}

.more-projects p {
  font-family: "Gramatika-Black";
  font-size: 1.704433497536944vh;
  line-height: 127.3%;
  text-align: center;
  color: #ffffff;
  margin: 0;
  transition: 0.5s;
}

.more-projects:hover p {
  color: #ffffff;
}

.more-projects img {
  transition: 0.5s;
  filter: saturate(3.5);
}

.more-projects:hover img {
  filter: saturate(1);
}

.flecha-ar {
  width: 4.6875vh;
  height: 4.6875vh;
  background-size: 4.6875vh;
  background-image: url("../../assets/arrow-right.svg");
  transition: 0.5s;
}

.more-projects:hover .flecha-ar {
  background-image: url("../../assets/btn-pink.svg");
}

.letras p {
  font-family: Gramatika-Medium;
  font-size: 20.01953125vh;
  margin: 0;
  line-height: 109.3%;
  color: white;
  transition: 0.5s ease-out;
}

.letras {
  margin-left: 45.38671875vh;
  width: 107vh;
}

.letras p.pink {
  color: #ffb6ea;
}

.letras .l-pc {
  display: block;
}

.letras .l-mo {
  display: none;
}

/*
.sticky-l p {
    position: relative;
    z-index: 502;
}*/
.sticky-l {
  position: relative;
  transition: 0.5s;
}

@media (max-width: 768px) {
  #Integrantes {
    margin-left: 0;
    align-items: flex-start;
  }

  .flecha-ar {
    width: 6.521739130434782vh;
    height: 6.521739130434782vh;
    background-size: 6.521739130434782vh;
  }

  .more-projects a {
    width: 26vh;
  }

  .frame:nth-child(1) {
    position: relative;
    top: 13.423645320197043vh;
    left: 8.251231527093594vh;
    width: 24.728260869565215vh !important;
    height: 40.21739130434782vh;
    z-index: 9;
  }

  .frame:nth-child(2) {
    position: relative;
    top: 23.64532019704433vh;
    left: 7.065217391304348vh;
    width: 30.706521739130434vh !important;
    height: 26.08695652173913vh;
   }

  .frame:nth-child(3) {
    position: relative;
    top: 50vh;
    left: -65.065217vh;
    width: 19.293478260869563vh !important;
    height: 23.23369565217391vh;
   }

  .frame:nth-child(4) {
    display: block;
    position: relative;
    top: 60.597826086956516vh;
    left: -49.065217vh;
    width: 38.586956521739125vh !important;
    height: 44.701086956521735vh;
    /* background-image: url("../../assets/frame-4-mo.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    transition: 0.5s;
  }

  .frame:nth-child(1) .imagen-frame {
    background-size: 110%;
  }

  .frame:nth-child(3) .imagen-frame {
    background-image: url("../../assets/image-9-m.png") !important;
  }

  .frameTres div {
    background-image: url("../../assets/frame-4.png") !important;
  }

  .frame:nth-child(1) .imagen-frame:hover {
    background-size: 110%;
  }

  .imagen-frame:hover {
    background-size: 100%;
  }

  .more-projects.pc {
    display: none;
  }

  .more-projects.mo {
    display: block;
    margin-top: 46.19565217391304vh;
    position: relative;
    z-index: 92;
    margin-left: -11vh;
  }

  .imagen-frame {
    width: inherit;
    height: 100%;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.5s;
  }

  .letras {
    margin-left: 0;
    width: fit-content;
  }

  .lr-cont {
    margin-left: 17vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 29vh;
  }

  .letras .l-pc {
    display: none;
  }

  .letras .l-mo {
    display: block;
    position: relative;
  }

  .letras p {
    font-size: 10.869565217391305vh;
    line-height: 109.3%;
  }

  .scroll-services {
    display: flex;
    align-items: center;
  }

  .integrantes-cont {
    display: flex;
    justify-content: flex-start;
    /*width: 234.535156vh;*/
    width: 136.535156vh;
    position: relative;
    transition: 3s;
  }

  .lr-cont {
    margin: 0 20vh 0 15vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 29vh;
  }

  /**circulo cont */
  .circulo-vm {
    width: 15.2173vh;
    height: 15.2173vh;
    border: 1px solid #3702FB;
    border-radius: 22vh;
    /*display: flex;
    align-items: center;
    justify-content: center;*/
    margin: 0;
    background: #3702FB;
  }

  .more-projects p {
    font-family: "Gramatika-Bold";
    font-size: 1.7663vh;
    width: auto;
    margin: 6.25vh 3.125vh 0 3.260vh;
    line-height: 106.8%;
  }
}</style>