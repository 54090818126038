<template>
  <div id="HistoriasExito" class="HistoriasExito">
    <div class="container hiscontainer">
      <div class="tira" style="top: 1vh">
        <img
          src="../../assets/tira-larga.png"
          class="moviles"
          alt="moviles "
          width="242"
          height="1451"
        />
        <img
          src="../../assets/tira-larga.png"
          class="moviles"
          alt="moviles "
          width="242"
          height="1451"
        />
        <img
          src="../../assets/tira-larga.png"
          class="moviles"
          alt="moviles "
          width="242"
          height="1451"
        />
      </div>
    <a href="/historias-de-exito" class="liga-stories"></a>
    </div>
  </div>
</template>
<script>
export default {
  methods: {},
};
</script>
<style scoped>
/*div#HistoriasExito {
    position: relative;
    padding: 0 23vw;
}*/
#HistoriasExito {
  position: relative;
  z-index: 500;
  /* transition: .05s;*/
   transition: .25s ease-out;
  /* margin-left: -170vh;*/
}
.container {
 /* background-color: transparent;*/
    background-color: #b2acfc;
  height: 100%;
  width: 125.68359375vh;
  overflow: auto;
  background-image: url("../../assets/moviles.svg");
  background-repeat: no-repeat;
  background-size: 68.06640625vh;
  background-position-x: center;
  background-position-y: center;
  overflow: hidden;
  margin-right: 0;
  margin-left: 0;
  display: flex;
  align-items: center;
}
.liga-stories {
    width: 26vh;
    height: 44vh;
    background: transparent;
    margin-left: 11vh;
    margin-bottom: 9vh;
    cursor: none;
}
.moviles {
  position: relative;
  width: 23.6328125vh;
  height: auto;
  transition: .5s;
}
.tira {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 32.60938vh;
  width: 23.6328125vh;
  transition: .25s linear;
}
@media (max-width: 768px) {
  .container {
    background-color: #b2acfc;
    height: 100%;
    width: 83.983594vh;
    background-size: 49.45652173913043vh;
    margin-right: 0;
    margin-left: 0;
  }
  .moviles {
    position: relative;
    width: 17.119565217391305vh;
    margin-left: 0;
  }
  .tira {
    margin-left: 19.90938vh ;
    width: 17.119565217391305vh;
  }
}
</style>