<template >
  <div id="Home" @scroll="scrollX">
    <div class="cursorjont last-pro-cursor">Ver más</div>
    <div class="transp-cursor"></div>
    <Header />
    <div class="titulo-enlace-serv">
      <p class="titulo-en">{{ this.servicesTitle }}</p>
      <a :href="this.webpage">
        <div class="circle-serv">Conoce más</div>
      </a>
    </div>
    <FixedItems />
    <FixedTitle />
    <WAppIcon link="https://wa.link/yftlly" />
    <div
      class="scroll-cont"
      id="scroll-cont"
      ref="scroll-cont"
      :class="{ scrollx: scrollEBeta > 2909 }"
    >
      <!--  :style="{'height':height()}"-->
      <div
        id="smooth-scroll"
        class="smooth-scroll"
      >
        <div class="first-part">
          <div class="bkcolor">
            <PrimeraSeccion />
            <UltimosProyectos />
          </div>
        </div>
        <div class="sticky-pro">
          <About :scrollP="this.scrollEBeta" class="about-sec" />
        </div>
        <Teamates :scrollP="this.scrollEBeta" />
        <div class="historia-an">
          <Stories />
        </div>
        <Servicios />
        <Partners />
        <BlogSec />
        <Title />
        <Contact />
      </div>
    </div>
  </div>
</template>
<script>
/**WAppIcon */
import Header from "../components/on-site/Header";
import FixedTitle from "../components/on-site/FixedTitle";
import WAppIcon from "../components/on-site/WAppIcon";
import FixedItems from "../components/on-site/FixedItems";
import PrimeraSeccion from "../components/home/PrimeraSeccion";
import UltimosProyectos from "../components/home/UltimosProyectos";
import About from "../components/home/About";
import Teamates from "../components/home/Teamates";
import Stories from "../components/home/Stories";
import Servicios from "../components/home/Servicios";
import Partners from "../components/home/Partners";
import BlogSec from "../components/home/BlogSec";
import Title from "../components/home/Title";
import Contact from "../components/home/Contact";
import ICountUp from "vue-countup-v2";

export default {
  name: "Home",
  data() {
    return {
      scrollPosition: null,
      scrollEBeta: null,
      number: null,
      servicesTitle: "Developing",
      webpage: "/services/development",
      counter: 0,

      delay: 1000,
      endVal: 120500,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
    };
  },
  components: {
    Header,
    FixedTitle,
    FixedItems,
    PrimeraSeccion,
    UltimosProyectos,
    About,
    Teamates,
    Stories,
    Servicios,
    Partners,
    BlogSec,
    Title,
    Contact,
    WAppIcon,
    ICountUp,
  },
  methods: {
    bodyBG() {
      if (scrollPosition > 18599) {
        document.getElementById("Home").style.backgroundColor = purple;
      }
    },
    /*
    height(){
      let pantalla1 = window.matchMedia("(max-width: 1440px)");
      if(pantalla1.matches){
        return 3905+"vh"
      }else{
        return 3375+"vh" 
      }
    },
    */
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    },
    updateScrollX() {
      return this.$refs["scroll-cont"].scrollLeft;
    },
    loadpage() {
      let min768 = window.matchMedia("(min-width: 768px)");
      let media768 = window.matchMedia("(max-width: 768px)");

if(media768.matches){
       if (this.$route.params.name == "lastest-proyects") {
        window.scrollTo(0, 564);
      } else if (this.$route.params.name == "about") {
        window.scrollTo(0, 3874);
      } else if (this.$route.params.name == "team") {
        window.scrollTo(0, 8731);
      } else if (this.$route.params.name == "stories") {
        window.scrollTo(0, 5963);
      } else if (this.$route.params.name == "services") {
        window.scrollTo(0, 9475);
      }  else if (this.$route.params.name == "partners") {
        window.scrollTo(0, 17354);
      } else if (this.$route.params.name == "magazine") {
        window.scrollTo(0, 22920);
      } else if (this.$route.params.name == "contact-us") {
        window.scrollTo(0, 24954);
      } else {
        window.scrollTo(0, 0);
      } 
}
else if(min768.matches){
      if (this.$route.params.name == "lastest-proyects") {
        window.scrollTo(0, 2402);
      } else if (this.$route.params.name == "about") {
        window.scrollTo(0, 4875);
      } else if (this.$route.params.name == "team") {
        window.scrollTo(0, 8365);
      } else if (this.$route.params.name == "stories") {
        window.scrollTo(0, 12436);
      } else if (this.$route.params.name == "services") {
        window.scrollTo(0, 14136);
      }  else if (this.$route.params.name == "partners") {
        window.scrollTo(0, 31000);
      } else if (this.$route.params.name == "magazine") {
        window.scrollTo(0, 40236);
      }  else if (this.$route.params.name == "contact-us") {
        window.scrollTo(0, 40841);
      } else {
        window.scrollTo(0, 0);
      }   
}

    },
    routeLoad(event) {
      let divcont = document.getElementsByClassName("smooth-scroll")[0];
      let min768 = window.matchMedia("(min-width: 768px)");
      let media768 = window.matchMedia("(max-width: 768px)");
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let offset1 = offset - 738;
      let offset2 = offset - 912;
      let offsetdue = offset - 2692;
      let offsettre = offset - 2850;
      let offsetquatro = offset - 3100;
      let mediaH1080 = window.matchMedia("(max-height: 1080px)");
      let mediaH1000 = window.matchMedia("(max-height: 1000px)");
      let mediaH900 = window.matchMedia("(max-height: 900px)");

      /**2621 3706*/
      if (media768.matches) {
        if (offset > 346 && offset < 548) {
          divcont.style.transform = "translate(" + -346 + "vh" + ",0vh" + ")";
        } else if (offset > 1346 && offset < 1471) {
          divcont.style.transform = "translate(" + -1368 + "vh" + ",0vh" + ")";
        } else if (offset > 1501 && offset < 1645) {
          divcont.style.transform = "translate(" + -1519 + "vh" + ",0vh" + ")";
        } else if (offset > 1681 && offset < 1801) {
          divcont.style.transform = "translate(" + -1722 + "vh" + ",0vh" + ")";
        } else if (offset > 2163 && offset < 2269) {
          divcont.style.transform = "translate(" + -2166 + "vh" + ",0vh" + ")";
        } else if (offset > 2274) {
          divcont.style.transform = "translate(" + -2274 + "vh" + ",0vh" + ")";
        } else {
          divcont.style.transform =
            "translate(" + offset * -1 + "vh" + "," + "0vh" + ")";
        }
      } else if (min768.matches) {
        /**1317 
        if (offset < 2509) {
          divcont.style.transition = ".25s";
        }
        if (offset > 1317) {
          divcont.style.transition = "0s";
        } 
        if (offset < 2509) {
          divcont.style.transition = ".25s";
        }
        if (offset > 2509) {
          divcont.style.transition = 0.25s ease-out;
        }*/
        if (offset < 677) {
          divcont.style.WebkitTransition = "0s";
          divcont.style.MozTransition = "0s";
        }
        if (offset > 677) {
          divcont.style.WebkitTransition = "0.25s ease-out";
          divcont.style.MozTransition = "0.25s ease-out";
        }

        if (offset > 512 && offset < 2509 /*1567*/) {
          divcont.style.transform = "translate(" + 0 + "vh" + ",0vh" + ")";
        } else if (offset > 2509 && offset < 2692) {
          divcont.style.transform = "translate(" + 0 + "vh" + ",0vh" + ")";
        } else if (offset > 2692 && offset < 2913) {
          divcont.style.transform =
            "translate(" + -offsetdue + "vh" + ",0vh" + ")";
        } else if (offset > 2913 && offset < 3076) {
          divcont.style.transform =
            "translate(" + -228.462 + "vh" + ",0vh" + ")";
        } else if (offset > 3076 && offset < 3317) {
          divcont.style.transform =
            "translate(" + -offsettre + "vh" + ",0vh" + ")";
        } else if (offset > 3317 && offset < 3567) {
          divcont.style.transform =
            "translate(" + -465.308 + "vh" + ",0vh" + ")";
        } else if (offset > 3567 && offset < 3798) {
          divcont.style.transform =
            "translate(" + -offsetquatro + "vh" + ",0vh" + ")";
        } else if (offset > 3798) {
          divcont.style.transform =
            "translate(" + -707.29 + "vh" + ",0vh" + ")";
        } else if (offset <= 512) {
          /**3898.461538461538
           */
          divcont.style.transform = "translate(" + 0 + "vh" + ",0vh" + ")";
        } else {
          divcont.style.transform =
            "translate(" + offset * -1 + "vh" + "," + "0vh" + ")";
        }
      }
      /*if (media768.matches) {
      } else if (min768.matches) {
        if(this.$route.params.name == 'partners'){
          divcont.style.transform = "translate(" + -2991 + "vh" + ",0vh" + ")";
        }
      }*/
    },
    /**HOME EFFECTS */
    scrollX(event) {
      let divcont = document.getElementsByClassName("smooth-scroll")[0];
      let min768 = window.matchMedia("(min-width: 768px)");
      let media768 = window.matchMedia("(max-width: 768px)");
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let offset1 = offset - 738;
      let offset2 = offset - 912;
      let offsetdue = offset - 2692;
      let offsettre = offset - 2850;
      let offsetquatro = offset - 3100;
      let offsetcincue = offset - 160;
      let offsetsei = offset - 545;
      let mediaH1080 = window.matchMedia("(max-height: 1080px)");
      let mediaH1000 = window.matchMedia("(max-height: 1000px)");
      let mediaH900 = window.matchMedia("(max-height: 900px)");

      /**2621 3706*/
      if (media768.matches) {
          divcont.style.transition = "0s";
        if (offset > 443 && offset < 530 
        || offset > 1268 && offset < 1327
        || offset > 1346 /*&& offset < 1614
        || offset > 1650 && offset < 1682
        || offset > 1986 */&& offset < 2007
        || offset > 2381 && offset < 2397) {
          divcont.style.transition = ".5s";
        }
        if (offset > 324 && offset < 515) {
          divcont.style.transform = "translate(" + -324 + "vh" + ",0vh" + ")";
        } else if (offset > 1163 && offset < 1304/**/) {
          divcont.style.transform = "translate(" + -1163 + "vh" + ",0vh" + ")";
        } else if (offset > 1304 && offset < 1630) {
          divcont.style.transform = "translate(" + -1305 + "vh" + ",0vh" + ")";
        } else if (offset > 1630 && offset < 1664) {
          divcont.style.transform =
            "translate(" + offsetcincue * -1 + "vh" + ",0vh" + ")";
        } else if (offset > 1664 && offset < 1999) {
          divcont.style.transform =
            "translate(" + -1509.69 + "vh" + ",0vh" + ")";
        }else if (offset > 1999 && offset < 2130) {
          divcont.style.transform =
            "translate(" + -1582.77 + "vh" + ",0vh" + ")";
        } else if (offset > 2130 && offset < 2390) {/**/
          divcont.style.transform =
            "translate(" + offsetsei * -1 + "vh" + ",0vh" + ")";
        } else if (offset > 2390) {
          divcont.style.transform = "translate(" + -1869.38 + "vh" + ",0vh" + ")";
        }/**/ else {

        /*else if (offset > 2520) {
          divcont.style.transform = "translate(" + -2274 + "vh" + ",0vh" + ")";
        }else if (offset > 2274) {
          divcont.style.transform = "translate(" + -2274 + "vh" + ",0vh" + ")";
        }else if (offset > 2274) {
          divcont.style.transform = "translate(" + -2274 + "vh" + ",0vh" + ")";
        }
        */
          divcont.style.transform =
            "translate(" + offset * -1 + "vh" + "," + "0vh" + ")";
        }
      } else if (min768.matches) {
        /**1317 
        if (offset < 2509) {
          divcont.style.transition = ".25s";
        }
        if (offset > 1317) {
          divcont.style.transition = "0s";
        } 
        if (offset < 2509) {
          divcont.style.transition = ".25s";
        }
        if (offset > 2509) {
          divcont.style.transition = 0.25s ease-out;
        }*/
        if (offset < 677) {
          divcont.style.WebkitTransition = "0s";
          divcont.style.MozTransition = "0s";
        }
        if (offset > 677) {
          divcont.style.WebkitTransition = "0.25s ease-out";
          divcont.style.MozTransition = "0.25s ease-out";
        }

        if (offset > 512 && offset < 2509 /*1567*/) {
          divcont.style.transform = "translate(" + 0 + "vh" + ",0vh" + ")";
        } else if (offset > 2509 && offset < 2692) {
          divcont.style.transform = "translate(" + 0 + "vh" + ",0vh" + ")";
        } else if (offset > 2692 && offset < 2913) {
          divcont.style.transform =
            "translate(" + -offsetdue + "vh" + ",0vh" + ")";
        } else if (offset > 2913 && offset < 3076) {
          divcont.style.transform =
            "translate(" + -228.462 + "vh" + ",0vh" + ")";
        } else if (offset > 3076 && offset < 3317) {
          divcont.style.transform =
            "translate(" + -offsettre + "vh" + ",0vh" + ")";
        } else if (offset > 3317 && offset < 3567) {
          divcont.style.transform =
            "translate(" + -465.308 + "vh" + ",0vh" + ")";
        } else if (offset > 3567 && offset < 3798) {
          divcont.style.transform =
            "translate(" + -offsetquatro + "vh" + ",0vh" + ")";
        } else if (offset > 3798) {
          divcont.style.transform =
            "translate(" + -707.29 + "vh" + ",0vh" + ")";
        } else if (offset <= 512) {
          /**3898.461538461538
           */
          divcont.style.transform = "translate(" + 0 + "vh" + ",0vh" + ")";
        } else {
          divcont.style.transform =
            "translate(" + offset * -1 + "vh" + "," + "0vh" + ")";
        }
      }
    },

    /**EFECTO TITULO SERVICIOS*/
    scrollServicios(event) {
      let S1 = document.getElementsByClassName("span-stc1")[0];
      let E1 = document.getElementsByClassName("span-stc2")[0];
      let R1 = document.getElementsByClassName("span-stc3")[0];
      let V1 = document.getElementsByClassName("span-stc4")[0];
      let I1 = document.getElementsByClassName("span-stc5")[0];
      let C1 = document.getElementsByClassName("span-stc6")[0];
      let I2 = document.getElementsByClassName("span-stc7")[0];
      let O1 = document.getElementsByClassName("span-stc8")[0];
      /*let S2 = document.getElementsByClassName("span-stc9")[0]; */
      let services = document.getElementsByClassName("Servicios")[0];
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let mx1 = offset - 989;
      let mx2 = offset - 1213;
      if (media768.matches) {
        /**S */
        if (offset > 876) {
          S1.style.top = 0 + "px";
        }
        if (offset < 876) {
          S1.style.top = 950 + "px";
        }
        /**E */
        if (offset > 904) {
          E1.style.top = 0 + "px";
        }
        if (offset < 904) {
          E1.style.top = -950 + "px";
        }
        /**R */
        if (offset > 933) {
          R1.style.top = 0 + "px";
        }
        if (offset < 933) {
          R1.style.top = 950 + "px";
        }
        /**V */
        if (offset > 957) {
          V1.style.top = 0 + "px";
        }
        if (offset < 957) {
          V1.style.top = -950 + "px";
        }
        /**I */
        if (offset > 976) {
          I1.style.top = 0 + "px";
        }
        if (offset < 976) {
          I1.style.top = 950 + "px";
        }
        /**C */
        if (offset > 996) {
          C1.style.top = 0 + "px";
        }
        if (offset < 996) {
          C1.style.top = -950 + "px";
        }
        /**E */
        if (offset > 1025) {
          I2.style.top = 0 + "px";
        }
        if (offset < 1025) {
          I2.style.top = 950 + "px";
        }
        /**S */
        if (offset > 1058) {
          O1.style.top = 0 + "px";
        }
        if (offset < 1058) {
          O1.style.top = -950 + "px";
        }
        /*if (offset < 1116) {
        services.style.paddingLeft = mx1 * 0.1 + "vh";
        }
        if (offset > 1116) {
          services.style.paddingLeft = -17 + "vh";
        }*/
        /**S
         if(offset > 1108){
              S2.style.top = 0+'px';
        }
         if(offset < 1108){
              S2.style.top = 950+'px';
        } */
      } else if (min768.matches) {
        /***1461.5384615384614 2509 2268*/
        if (offset < 1213) {
          services.style.marginLeft = -495 + "vh";
        }
        if (offset > 1213) {
          services.style.marginLeft = -595 - mx2 + "vh";
        }
        if (offset > 2252) {
          services.style.marginLeft = -1636.79 + "vh";
        }
        /**S */
        if (offset > 1213) {
          S1.style.top = 0 + "px";
        }
        if (offset < 1213) {
          S1.style.top = 950 + "px";
        }
        /**E */
        if (offset > 1299) {
          E1.style.top = 0 + "px";
        }
        if (offset < 1299) {
          E1.style.top = -950 + "px";
        }
        /**R */
        if (offset > 1350) {
          R1.style.top = 0 + "px";
        }
        if (offset < 1350) {
          R1.style.top = 950 + "px";
        }
        /**V */
        if (offset > 1406) {
          V1.style.top = 0 + "px";
        }
        if (offset < 1406) {
          V1.style.top = -950 + "px";
        }
        /**I */
        if (offset > 1448) {
          I1.style.top = 0 + "px";
        }
        if (offset < 1448) {
          I1.style.top = 950 + "px";
        }
        /**C */
        if (offset > 1487) {
          C1.style.top = 0 + "px";
        }
        if (offset < 1487) {
          C1.style.top = -950 + "px";
        }
        /**e */
        if (offset > 1535) {
          I2.style.top = 0 + "px";
        }
        if (offset < 1535) {
          I2.style.top = 950 + "px";
        }
        /**s */
        if (offset > 1592) {
          O1.style.top = 0 + "px";
        }
        if (offset < 1592) {
          O1.style.top = -950 + "px";
        }
      }
    },

    /**ENTRADA BLOG SCROLL */
    blogScroll(event) {
      let divcont = document.getElementsByClassName("blog-cont")[0];
      let media768 = window.matchMedia("(max-width: 768px)");
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;

      let fate = offset * -0.1 + 80;

      if (media768.matches) {
        if (offset < 1836) {
          divcont.style.left = 0 + "vh";
          divcont.style.paddingLeft = 0 + "vh";
          divcont.style.opacity = 0;
        } else if (offset > 1836) {
          divcont.style.left = 0 + "vh";
          divcont.style.paddingLeft = 0 + "vh";
          divcont.style.opacity = 1;
        }
      } else {
        if (offset < 2378) {
          divcont.style.left = 200 + "vh";
        } else if (offset > 2378) {
          divcont.style.left = 0 + "vh";
        }
      }
    },

    /*IGUANA PARTNERS*/
    iguanaScroll(event) {
      let divcont = document.getElementsByClassName("fondoiguana")[0];
      let divcont1 = document.getElementsByClassName("fondoiguana2")[0];
      let divcont2 = document.getElementsByClassName("titulo-psc")[0];
      /*let dc4 = document.getElementById("#partnerList");*/
      /*let divcont4 = document.getElementsByClassName("list-bar")[0];tpc-text*/
      let divcont5 = document.getElementsByClassName("conect")[0];
      let divcont55 = document.getElementsByClassName("tpc-text")[0];
      let divcont6 = document.getElementsByClassName("merchtxt")[0];
      let divcont7 = document.getElementsByClassName("carSlideCont1")[0];
      let divcont75 = document.getElementsByClassName("carSlideCont2")[0];
      let divcont8 = document.getElementsByClassName("blog-items")[0];
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      /*let mx1 = offset - 1530;*/
      /*let mx2 = offset - 1373;*/
     /* let mx3 = offset - 2979;*/
      let mx4 = offset - 3336;
      let mx5 = offset - 2172;
      let mx6 = offset - 2710;
      /*let suma = -15 - mx2 * 0.2;*/
      let b1 = offset - 1250;
     /* let b2 = offset - 1657;

      let fate = offset * -0.1 + 80;*/
      //1754
      /**/
      if (media768.matches) {
        if (offset < 1250) {
          divcont.style.left = 25 + "vh";
          /*divcont1.style.left = 25 + "vh";*/
          /*divcont.style.transform = "translate3d("+43+ "vh, 0, 0)";
          divcont1.style.transform = "translate3d("+43+ "vh, 0, 0)";*/
        }
        if (offset > 1250) {
          divcont.style.left = 25-(b1*.25) + "vh";
          /*divcont1.style.left = 25-(b1*.25) + "vh";*/
          /*divcont.style.transform = "translate3d("+43-(b1*.3) + "vh, 0, 0)";
          divcont1.style.transform = "translate3d("+43-(b1*.3) + "vh, 0, 0)";*/
        }
        if (offset <= 1337) {
          divcont5.style.color = 'rgb(255 255 255 / 0)';
          divcont5.style.pointerEvents = "none";
          divcont55.style.pointerEvents = "none";
        }
        if (offset >= 1338) {
          divcont5.style.color = 'rgb(255 255 255)';
          divcont5.style.pointerEvents = "all";
          divcont55.style.pointerEvents = "all";
        }
        if (offset <= 1374) {
          divcont6.style.color = 'rgb(255 182 234 / 0)';
          divcont6.style.pointerEvents = "none";
        }
        if (offset >= 1375) {
          divcont6.style.color = 'rgb(255, 182, 234)';
          divcont6.style.pointerEvents = "all";
        }
        /*if (offset < 1656) {
          divcont4.style.top = 0 + "vh";
        }
        if (offset > 1657 && offset < 1858) { 
          divcont4.style.top = 0-b1 + "vh";
        }
        if (offset > 1859) {
          divcont4.style.top = -60.8192 + "vh";
        }*/
      } else if(min768.matches) {
        if (offset <= 2172) {
          divcont.style.left = 90 + "vh";
        }
        if (offset >= 2172) {
          divcont.style.left = 90 - mx5 * 0.15 + "vh";
        }
        if (offset <= 2329) {
          divcont5.style.color = 'rgb(255 255 255 / 0)';
          divcont5.style.pointerEvents = "none";
          divcont55.style.pointerEvents = "none";
        }
        if (offset >= 2330) {
          divcont5.style.color = 'rgb(255 255 255 / 1)';
          divcont5.style.pointerEvents = "all";
          divcont55.style.pointerEvents = "all";
        }
        if (offset <= 2429) {
          divcont6.style.color = 'rgb(255 182 234 / 0%)';
          divcont6.style.pointerEvents = "none";
        }
        if (offset >= 2430) {
          divcont6.style.color = 'rgb(255, 182, 234)';
          divcont6.style.pointerEvents = "all";
        }
        /*2710 3213*/
        if (offset <= 2710) {
          divcont2.style.left = 50 + "vh";
        }
        if (offset >= 2710) {
          divcont2.style.left = 50 - mx6 * 0.2 + "vh";
        }
        if (offset >= 3213) {
          divcont2.style.left = -50 + "vh";
        }

        if (offset <= 2998) {
          divcont7.style.opacity = 1;
          divcont75.style.opacity = 0;
        }
        if (offset >= 2999) {
          divcont7.style.opacity = 0;
          divcont75.style.opacity = 1;
        }

        if (offset < 3336) {
          divcont8.style.marginLeft = 0 + "vh";
        }
        if (offset > 3336) {
          divcont8.style.marginLeft = 0 - mx4 + "vh";
        }
      }
    },

    iscro(event) {

      let divcont4 = document.getElementsByClassName("list-bar")[0];
      /*let videoF = document.getElementsByClassName("rose-div")[0];*/
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let b1 = offset - 1666.7;

      if (media768.matches) {
          /*videoF.style.transition = '.5s';*/

        if (offset < 1666.7) {
          divcont4.style.top = 14.3423 + "vh";
        }
        if (offset > 1666.7 && offset < 1986 /**/) { 
          divcont4.style.top = (14.3423-b1*.33) + "vh";
        }/**/
        if (offset > 1986) {
          divcont4.style.top = -91.2044 + "vh";
        }
        /*if (offset < 2060) {
          videoF.style.background = 'rgba(239,103,194,1)';
        }
        if (offset > 2060) {
          videoF.style.background = 'rgba(239,103,194,0)';
        }*/
      } else if(min768.matches) {
          divcont4.style.top = 0 + "vh";
      }
    },
    scrollAbout(event) {
      /**aboutTitle 346.15384615384613 contactoFinal
       * .scroll-x about-t-c -5.6154vh 384*/
      let divcont = document.getElementsByClassName("listAbout")[0];
      let divcont1 = document.getElementsByClassName("video-head")[0];
      let divcont2 = document.getElementsByClassName("about-t-c")[0];
      let divcont3 = document.getElementsByClassName("scroll-x")[0];
      let divcont4 = document.getElementsByClassName("integrantes-cont")[0];
      let divcont5 = document.getElementsByClassName("contactoFinal")[0];
      let lista1 = document.getElementsByClassName("primer-p")[0];
      let lista2 = document.getElementsByClassName("segundo-p")[0];
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let mx = offset - 374;
      let mx1 = offset - 393;
      let mx2 = offset - 523;
      let mx3 = offset - 616;
      let mx4 = offset - 394;
      let lol = offset - 284;
      let st = window.pageYOffset || divcont;
      if (media768.matches) {
        if (offset <= 354) {
          divcont1.style.transform =
            "translate(0vh" + "," + 17.487684729064036 + "vh" + ")";
          divcont2.style.opacity = 0;
        }
        if (offset >= 354) {
          divcont1.style.transform =
            "translate(" + "0vh" + "," + mx * -1 + "vh" + ")";
          divcont2.style.opacity = 1;
        }
        if (offset >= 384) {
          divcont1.style.transform =
            "translate(" + "0vh" + "," + -8.6154 + "vh" + ")";
          divcont2.style.opacity = 1;
        }
        if (offset <= 384) {
          lista1.style.opacity = 1;
          lista2.style.opacity = 0;
          divcont.style.top = 12 + "vh";
        } else if (offset >= 432) {
          lista1.style.opacity = 0;
          lista2.style.opacity = 1;
          divcont.style.top = -39.6923 + "vh";
          if (offset < 461) {
            divcont.style.opacity = 1;
            divcont.style.transform = "scale(" + 1 + ")";
            divcont1.style.opacity = 1;
            divcont.style.left = 0;
            divcont1.style.transform = "scale(" + 1 + ")";
            divcont1.style.transform =
              "translate(" + "0vh" + "," + -8.6154 + "vh" + ")";
          }
          if (offset > 461) {
            divcont.style.opacity = 0.69;
            divcont.style.transform = "scale(" + 1.1 + ")";
            divcont1.style.opacity = 0.69;
            divcont.style.left = 5 + "vh";
            divcont1.style.transform = "scale(" + 1.1 + ")";
            divcont1.style.transform =
              "translate(" + "0vh" + "," + -8.6154 + "vh" + ")";
          }
          if (offset > 471) {
            divcont.style.opacity = 0.55;
            divcont.style.transform = "scale(" + 1.2 + ")";
            divcont1.style.opacity = 0.55;
            divcont.style.left = 5 + "vh";
            divcont1.style.transform = "scale(" + 1.2 + ")";
            divcont1.style.transform =
              "translate(" + "0vh" + "," + -8.6154 + "vh" + ")";
          }
          if (offset > 481) {
            divcont.style.opacity = 0.42;
            divcont.style.transform = "scale(" + 1.3 + ")";
            divcont1.style.opacity = 0.42;
            divcont.style.left = 5 + "vh";
            divcont1.style.transform = "scale(" + 1.3 + ")";
            divcont1.style.transform =
              "translate(" + "0vh" + "," + -8.6154 + "vh" + ")";
          }
          if (offset > 491) {
            divcont.style.opacity = 0.28;
            divcont.style.transform = "scale(" + 1.4 + ")";
            divcont1.style.opacity = 0.28;
            divcont.style.left = 5 + "vh";
            divcont1.style.transform = "scale(" + 1.4 + ")";
            divcont1.style.transform =
              "translate(" + "0vh" + "," + -8.6154 + "vh" + ")";
          }
          if (offset > 501) {
            divcont.style.opacity = 0.14;
            divcont.style.transform = "scale(" + 1.5 + ")";
            divcont1.style.opacity =0.14;
            divcont.style.left = 5 + "vh";
            divcont1.style.transform = "scale(" + 1.5 + ")";
            divcont1.style.transform =
              "translate(" + "0vh" + "," + -8.6154 + "vh" + ")";
          }
          if (offset > 511) {
            divcont.style.opacity = 0;
            divcont.style.transform = "scale(" + 1.6 + ")";
            divcont1.style.opacity = 0;
            divcont.style.left = 5 + "vh";
            divcont1.style.transform = "scale(" + 1.6 + ")";
            divcont1.style.transform =
              "translate(" + "0vh" + "," + -8.6154 + "vh" + ")";
          }
        } else {
          divcont.style.top = mx1 * -1 + "vh";
          if (offset >= 384) {
            lista1.style.opacity = 0.8;
            lista2.style.opacity = 0.16;
          }
          if (offset >= 394) {
            lista1.style.opacity = 0.64;
            lista2.style.opacity = 0.32;
          }
          if (offset >= 403) {
            lista1.style.opacity = 0.48;
            lista2.style.opacity = 0.48;
          }
          if (offset >= 413) {
            lista1.style.opacity = 0.32;
            lista2.style.opacity = 0.64;
          }
          if (offset >= 423) {
            lista1.style.opacity = 0.16;
            lista2.style.opacity = 0.8;
          }
          if (offset >= 432) {
            lista1.style.opacity = 0;
            lista2.style.opacity = 1;
          } else if (offset <= 383) {
            /**461.5384615384615 */
            lista1.style.opacity = 1;
            lista2.style.opacity = 0;
          }
        }
      } else if (min768.matches) {
        /**PC V */
        if (offset <= 284) {
          divcont3.style.left = -223.8308 + "vh";
          divcont1.style.opacity = 0.15;
          divcont2.style.opacity = 0.15;
        }
        if (offset >= 284) {
          divcont3.style.left = -223.8308 - lol + "vh";
        }
        if (offset >= 394) {
          divcont1.style.opacity = 0.15;
          divcont2.style.opacity = 0.15;
        }
        if (offset >= 415) {
          divcont1.style.opacity = 0.15;
          divcont2.style.opacity = 0.15;
        }

        if (offset <= 416) {
          divcont1.style.filter = "blur(9px)";
        }
        if (offset >= 417) {
          divcont1.style.filter = "blur(0px)";
        }
        if (offset >= 430) {
          divcont1.style.opacity = 0.6;
          divcont2.style.opacity = 0.6;
        }
        /*
        if(offset >= 549){
          divcont3.style.top = 75+'vh'
        } if(offset >= 559){
          divcont3.style.top = 50+'vh'
        } if(offset >= 569){
          divcont3.style.top = 25+'vh'
        }*/ if (offset >= 462) {
          divcont1.style.opacity = 1;
          divcont2.style.opacity = 1;
        }
        if (offset >= 462) {
          divcont3.style.left = -405.6 + "vh";
        }
        /*if (offset <= 495) {
          divcont3.style.WebkitTransition = ".25s cubic-bezier(0.49, 0.47, 0.68, 0.68)";
          divcont3.style.MozTransition  = ".25s cubic-bezier(0.49, 0.47, 0.68, 0.68)";
        }
        if (offset >= 495) {
          divcont3.style.WebkitTransition = "0s";
          divcont3.style.MozTransition  = "0s";
        }*/
        /*if (offset >= 512) {
          divcont3.style.left = 103.6 + "vh";
        }*/

        if (offset <= 523) {
          divcont.style.top = 0 + "vh";
          lista1.style.opacity = 1;
          lista2.style.opacity = 0;
        }
        if (offset >= 523) {
          divcont.style.top = -mx2 + "vh";
          lista1.style.opacity = 1;
          lista2.style.opacity = 0;
        }
        if (offset >= 532) {
          lista1.style.opacity = 0.5;
          lista2.style.opacity = 0.25;
        }
        if (offset >= 542) {
          lista1.style.opacity = 0;
          lista2.style.opacity = 0.5;
        }
        if (offset >= 552) {
          lista1.style.opacity = 0;
          lista2.style.opacity = 0.75;
        }
        if (offset >= 562) {
          divcont.style.top = -38.4615 + "vh";
          lista1.style.opacity = 0;
          lista2.style.opacity = 1;
        }
        if (offset >= 590) {
          divcont1.style.opacity = 0;
        }
        if (offset <= 590) {
          divcont2.style.pointerEvents = "all";
          divcont.style.opacity = 1;
          divcont2.style.left = 0 + "vh";
        }
        if (offset >= 610) {
          divcont2.style.pointerEvents = "none";
          divcont2.style.left = -78 + "vh";
          divcont.style.opacity = 0;
        }
        if (offset <= 616) {
          divcont4.style.marginLeft = -547 + "vh";
        }
        if (offset >= 616) {
          divcont4.style.marginLeft = -547 - mx3 + "vh";
        }
        if (offset <= 1038) {
          /*divcont2.style.opacity = 1*/
        }
        if (offset >= 1038) {
          divcont2.style.opacity = 0;
        }
        if (offset <= 3756) {
          divcont5.style.backgroundPositionY = "-13000%";
        }
        if (offset >= 3756) {
          divcont5.style.backgroundPositionY = "0%";
        }
      }
    },
    /***749.9999999999999 2980 3105*/

    /*ANIMACIONES TEAMATES */
    teamatesAnimation(event) {
      let frame1 = document.getElementsByClassName("frameUno")[0];
      let frame2 = document.getElementsByClassName("frameDos")[0];
      let frame3 = document.getElementsByClassName("frameTres")[0];
      let frame4 = document.getElementsByClassName("frameCuatro")[0];
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let mx = offset - 605;
      let mx1 = offset - 393;
      let mx2 = offset - 374;
      let mx3 = offset - 993;
      if (media768.matches) {
        /**frameTres */
        if (offset <= 528) {
          frame1.style.left = 8.251231527093594 + "vh";
          frame2.style.left = 7.065217391304348 + "vh";
          frame3.style.left = -65.065217 + "vh";
          frame4.style.left = -49.065217 + "vh";
        }
        if (offset >= 528) {
          frame1.style.left = mx * 0.3 + "vh";
          frame2.style.left = mx * 0.35 + "vh";
          frame3.style.left = mx3 * 0.2 + "vh";
          frame4.style.left = -49.065217 + "vh";
        }
      }
    },

    /*TIRA HISTORIAS*/
    storyScroll(event) {
      let divcont = document.getElementsByClassName("tira")[0];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let mx1 = offset - 1020;
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let fate = offset * -0.26 + 180;
      if (media768.matches) {
        if (offset < 778) {
          divcont.style.top = -3.65385 + "%";
        } else if (offset > 903) {
          divcont.style.scrollTop = -27.6923 + "%";
        } else {
          divcont.style.top = fate + "%";
        }
      } else if (min768.matches) {
        if (offset <= 1020) {
          divcont.style.top = -24.4615 + "vh";
        }
        if (offset >= 1020) {
          divcont.style.top = -24.4615 - mx1 * 0.5 + "vh";
        }
        if (offset >= 1322) {
          divcont.style.top = -175.231 + "vh";
        }
      }
    },

    /***WE ALWAYS FIND CREATIVITY ANIMACION */
    parrafosServ(event) {
      let parrafo1 = document.getElementsByClassName("wuno")[0];
      let parrafo2 = document.getElementsByClassName("wdos")[0];
      let parrafo3 = document.getElementsByClassName("wtres")[0];
      let parrafo2p = document.getElementsByClassName("wdosp")[0];
      let parrafo3p = document.getElementsByClassName("wtresp")[0];
      let parrafo4 = document.getElementsByClassName("wcuatro")[0];
      let stickL = document.getElementsByClassName("sticky-l")[0];
      let hisEx = document.getElementsByClassName("HistoriasExito")[0];
      let hisEx1 = document.getElementsByClassName("hiscontainer")[0];
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let mx1 = offset - 768;
      let mx2 = offset + 518;
      let mx3 = offset - 1003;
      let mx4 = offset - 1047;

      let fate = offset * -0.1 + 80;
      if (media768.matches) {
        parrafo1.style.left = mx2 * 0.015 + "vh";
        parrafo2p.style.left = mx1 * 0.1 + "vh";
        parrafo3p.style.left = mx2 * 0.02 + "vh";
        parrafo4.style.left = mx1 * 0.1 + "vh";
        /*if (offset >= 850) {
          parrafo1.style.left = mx2 * 0.005 + "vh";
          parrafo2p.style.left = mx1 * 0.01 + "vh";
          parrafo3p.style.left = mx2 * 0.004 + "vh";
          parrafo4.style.left = mx1 * 0.05 + "vh";
        }
        if (offset >= 923) {
          parrafo1.style.left = -16.23 + "vh";
          parrafo2p.style.left = -42.33 + "vh";
          parrafo3p.style.left = -32.46 + "vh";
          parrafo4.style.left = -42.33 + "vh";
        }*/
      } else if (min768.matches) {
        if (offset <= 900) {
          parrafo1.style.left = 0;
          parrafo2.style.left = 0;
          parrafo3.style.left = 0;
        }
        if (offset >= 900) {
          parrafo1.style.left = mx3 * 0.3 + "vh";
          parrafo2.style.left = mx3 * 0.1 + "vh";
          parrafo3.style.left = 50 - mx3 * 0.35 + "vh";
        }
        if (offset <= 1047) {
          hisEx.style.marginLeft = -576 + "vh";
        }
        if (offset >= 1047) {
          hisEx.style.marginLeft = -576 - mx4 + "vh";
        }
        if (offset <= 1357) {
          hisEx1.style.backgroundColor = "transparent";
        }
        if (offset >= 1357) {
          hisEx.style.marginLeft = -890 + "vh";
          hisEx1.style.backgroundColor = "#b2acfc";
        }
        if (offset < 1355) {
          stickL.style.opacity = "1";
        }
        /*if(offset > 1355){
          stickL.style.opacity = '0'
        }
        if(offset < 1317){
          hisEx.style.transition = '.25s'
        }
        if(offset > 1317){
          hisEx.style.transition = '0s'
        }
        if(offset > 1615){
          hisEx.style.transition = '.25s'
        }*/
      }
    },

    /**ANIMACIONES SERVICIOS */

    /**LASTEST PROYECTS */
    lastestProyects(event) {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let divcont = document.getElementsByClassName("iguana-diva")[0];
      let divcont1 = document.getElementsByClassName("queue-projects")[0];
      /*let queue = document.getElementsByClassName("queuePro")[0];
      let divcont2 = document.getElementsByClassName("lastProTitulo")[0]
      LastProjects;
      let divcont3 = document.getElementsByClassName("more-projects")[0];*/
      let divcont4 = document.getElementsByClassName("iguanaAnimacion")[0];
      let divcont5 = document.getElementsByClassName("tcPrincipal")[0];
      let divcont6 = document.getElementsByClassName('LastProjects')[0];
      let np = document.getElementsByClassName("newPrin")[0];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let mx1 = offset - /*182*/ 57;
      let mx1p1 = mx1 + this.counter;
      let mx2 = offset - 149;
      if (media768.matches) {
        divcont4.style.top = 0 + "vh";
        divcont5.style.opacity = 1;
        if (offset <= 19) {
          divcont.style.opacity = 1;
        }
        if (offset >= 19) {
          divcont.style.opacity = 0.75;
        }
        if (offset >= 28) {
          divcont.style.opacity = 0.5;
        }
        if (offset >= 38) {
          divcont.style.opacity = 0.25;
        }
        if (offset <= 48) {
          divcont1.style.left = 50 + "vh";
          /* divcont2.style.transform =
            "translate( 0vh" + "," + "0vh" + ")";*/
        }
        if (offset >= 48) {
          divcont.style.opacity = 0;
          divcont1.style.left = 0;
          /*divcont2.style.transform =
            "translate(" + (offset-56.9) + "vh" + "," + "0vh" + ")";*/
        }
        if (offset >= 240) {
          divcont.style.opacity = 0;
          divcont1.style.left = 0;
          /* divcont2.style.transform =
            "translate( 0vh" + "," + "0vh" + ")";*/
        }
        /* if(offset <= 249){
          divcont3.style.opacity = 0
        }
        if(offset >= 249){
          divcont3.style.opacity = 1
        }
        if(offset >= 298){
          divcont3.style.opacity = 0
        }*/
      } else if (min768.matches) {
        /**divcont4 */
        if (offset == 0) {
          divcont4.style.top = 0;
        }
        if (offset > 0) {
          divcont4.style.top = offset * 2 + "vh";
        } /*
        if(offset >= 75){
          divcont4.style.top = 50+'vh'
        }
        if(offset >= 100){
          divcont4.style.top = 65.5+'vh'
        }*/
        if (offset <= 50) {
          //queue.style.right = 0;
          divcont5.style.opacity = 1;
          divcont5.style.pointerEvents = "all";
          np.style.opacity = 1;
        }
        if (offset >= 50) {
          // queue.style.right = mx2 * 0.1 + "%";
          divcont4.style.top = 100 + "vh";
          divcont5.style.pointerEvents = "none";
          divcont5.style.opacity = 0;
          np.style.opacity = 0;
        }
        /* if(offset >= 165){
          divcont4.style.top = 87.5+'vh'
        }
        if(offset >= 180){
          divcont4.style.top = 100+'vh' 432
        }*/

        if (offset <= 56) {
         divcont6.style.left = 0 + "vh"; /**/
          divcont.style.pointerEvents = "all";
        }
        if (offset >= 57) {
          divcont.style.pointerEvents = "none";
          divcont6.style.left = -mx1p1 + "vh";
        }
        if (offset >= 432) {
          divcont6.style.left = -375.692 + "vh";
        }
      }
    },
    /***TITULO LAST ANIMATION 1971*/
    lastTitle(event) {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let l1 = document.getElementsByClassName("wtuno")[0];
      let l2 = document.getElementsByClassName("wtdos")[0];
      let l3 = document.getElementsByClassName("wttres")[0];
      let l4 = document.getElementsByClassName("wtcuatro")[0];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let m1 = offset - 2173;
      let m2 = offset - 3600;
      let m3 = offset - 3500;
      let m4 = offset - 3700;
      let m1m = offset - 2343;
      let m2m = offset - 2383;
      let m3m = offset - 2263;
      let m4m = offset - 2343;
      if (media768.matches) {
        l1.style.left = m1m * 0.17 + "vh";
        l2.style.left = m2m * 0.12 + "vh";
        l3.style.left = m3m * 0.19 + "vh";
        l4.style.left = m4m * 0.14 + "vh";
        /*****/
        if (offset >= 2406) {
          l1.style.left = 7 + "vh";
          l2.style.left = 5 + "vh";
          l3.style.left = 8 + "vh";
          l4.style.left = 6 + "vh";
        }
      } else if (min768.matches) {
        l1.style.left = m3 * 0.2 + "vh";
        l2.style.left = m4 * 0.1 + "vh";
        l3.style.left = m3 * 0.1 + "vh";
        l4.style.left = -m2 * 0.2 + "vh";
        if (offset >= 3898) {
          l1.style.left = 0 + "vh";
          l2.style.left = 0 + "vh";
          l3.style.left = 0 + "vh";
          l4.style.left = -0 + "vh";
        }
      }
    },

    /*CAMBIAR COLORES*/
    changeBackgroundPartners(event) {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      /***57.59615384615384
       * whiteword
       *   background-image: url("../assets/fondiu-1.svg"); morepro morep*/
      let divcont = document.getElementsByClassName("PartnersC")[0];
      let divcont2 = document.getElementsByClassName("servicios-secc")[0];
      let divcont3 = document.getElementsByClassName("bkcolor")[0];
      let divcont4 = document.getElementsByClassName("AcercaDe")[0];
      let morep = document.getElementsByClassName("morepro")[0];
      let textAnchor = document.getElementsByClassName("text-anchor-r")[0];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      if (media768.matches) {
        if (offset > 307.6923076923077) {
          //   morep.style.color = 'white';
          divcont3.style.backgroundColor = "#b2acfc";
          divcont4.style.backgroundColor = "#b2acfc";
        } else if (offset < 307.6923076923077) {
          //   morep.style.color = '#b2acfc';
          divcont3.style.backgroundColor = "transparent";
          divcont4.style.backgroundColor = "#e4e4f9";
        }
        if (offset > 1317) {
          divcont.style.backgroundColor = "#EF67C2";
          divcont2.style.backgroundColor = "#EF67C2";
        } else if (offset < 1317) {
          divcont.style.backgroundColor = "#B2ACFC";
          divcont2.style.backgroundColor = "#B2ACFC";
        }
      } else if (min768.matches) {
        if (offset > 2271) {
          divcont.style.backgroundColor = "#EF67C2";
          divcont2.style.backgroundColor = "#EF67C2";
        } else if (offset < 2271) {
          divcont.style.backgroundColor = "#B2ACFC";
          divcont2.style.backgroundColor = "#B2ACFC";
        }
        if (offset > 355) {
          //  morep.style.color = 'white';
          divcont3.style.backgroundColor = "#b2acfc";
          divcont4.style.backgroundColor = "#b2acfc";
          textAnchor.style.color = "#ef67c2!important";
        } else if (offset < 355) {
          //  morep.style.color = '#b2acfc';
          divcont3.style.backgroundColor = "transparent";
          divcont4.style.backgroundColor = "#e4e4f9";
          textAnchor.style.color = "white!important";
        }
      }
    },

    contactoAn(event) {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let divcont = document.getElementsByClassName("iguanafacha")[0];
      let divcont2 = document.getElementsByClassName("formcon")[0];
      let divcont3 = document.getElementsByClassName("contact-cont")[0];

      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let mx1 = offset-2403;
      if (media768.matches) {
        /* if (offset <= 2239) { 3272
          divcont3.style.top = -mx1+'vh';
        }*/
        if (offset <= 2403 /*&& offset <= 2324*/) {
          divcont3.style.top = 0 + "vh";
        }
        if (offset >= 2403 && offset <= 2534/**/) {
          divcont3.style.top = -mx1 + "vh";
        }
        if (offset >= 2534) {
          divcont3.style.top = -131.038 + "vh";
        }/**/
      } else if (min768.matches) {
        if (offset <= 2216) {
          divcont.style.opacity = 1;
        }
        if (offset >= 2216) {
          divcont.style.opacity = 1;
        }
      }
    },

    servicesEnlaces(event) {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let divcont = document.getElementsByClassName("titulo-enlace-serv")[0];
      let titulo = document.getElementsByClassName("titulo-en")[0];
      let circleserv = document.getElementsByClassName("circle-serv")[0];
      /**circle-serv */
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      if (media768.matches) {
        if (offset <= 1112) {
          divcont.style.pointerEvents = "none";
          divcont.style.opacity = 0;
        }
        if (offset >= 1112) {
          divcont.style.pointerEvents = "all";
          divcont.style.opacity = 1;
        }
        if (offset < 1148) {
          titulo.style.opacity = 1;
          circleserv.style.opacity = 1;
        }
        if (offset >= 1148) {
          titulo.style.opacity = 0;
          circleserv.style.opacity = 0;
        }
        if (offset <= 1158) {
          this.servicesTitle = "Development";
          this.webpage = "/services/development";
        }
        if (offset >= 1158) {
          this.servicesTitle = "Design & Branding";
          this.webpage = "/branding";
          titulo.style.color = "white";
        }
       /* if (offset <= 1168) {
          this.servicesTitle = "Development";
        }*/
        if (offset >= 1168) {
          titulo.style.opacity = 1;
          circleserv.style.opacity = 1;
        }
        if (offset >= 1204) {
          titulo.style.opacity = 0;
          circleserv.style.opacity = 0;
        }
        if (offset >= 1225) {
          this.servicesTitle = "Marketing";
          this.webpage = "/agencia-de-marketing ";
        }
        if (offset >= 1240) {
          titulo.style.opacity = 1;
          circleserv.style.opacity = 1;
          titulo.style.color = "white";
        }
        if (offset >= 1286) {
          divcont.style.pointerEvents = "none";
          divcont.style.opacity = 0;
        }
      } else if (min768.matches) {
        if (offset <= 1770) {
          divcont.style.pointerEvents = "none";
          divcont.style.opacity = 0;
        }
        if (offset >= 1770) {
          divcont.style.pointerEvents = "all";
          divcont.style.opacity = 1;
        }
        if (offset < 1886) {
          titulo.style.opacity = 1;
          circleserv.style.opacity = 1;
        }
        if (offset >= 1886) {
          titulo.style.opacity = 0;
          circleserv.style.opacity = 0;
        }
        if (offset <= 1912) {
          this.servicesTitle = "Development";
          this.webpage = "/services/development";
        }
        if (offset >= 1963) {
          titulo.style.opacity = 1;
          circleserv.style.opacity = 1;
          this.servicesTitle = "Design & Branding";
          this.webpage = "/branding";
          titulo.style.color = "white";
        }
        if (offset >= 2017) {
          titulo.style.opacity = 0;
          circleserv.style.opacity = 0;
        }
        if (offset >= 2070) {
          this.servicesTitle = "Marketing";
          this.webpage = "/agencia-de-marketing ";
        }
        if (offset >= 2121) {
          titulo.style.opacity = 1;
          circleserv.style.opacity = 1;
          titulo.style.color = "white";
        }
        if (offset >= 2217) {
          divcont.style.pointerEvents = "none";
          divcont.style.opacity = 0;
        }
      }
    },

    reloadmx(){
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      
      if (media768.matches) {
         location.reload();
      } else if (min768.matches) {
         location.reload();
      }
         

    }
    /**18% 2226 -30.7115vh 2701.9230769230767 2173.076923076923
     * 2267 2469 2216 2341
     */

    /*counterNumber() {
      let i = 0;
      //theLabel = document.getElementById("counter");
      let interval = setInterval(function () {
        if (i == 10) clearInterval(interval);
        i++;
      }, 100);
    },
    tennumber() {
      this.counter = 0;
    },*/
  },
  /*created() {
    this.counterNumber();
  },*/
  mounted() {
    this.loadpage();
    //window.addEventListener("scroll", this.tennumber);iscro
    // window.addEventListener("scroll", this.counterNumber);
    window.addEventListener("scroll", this.iscro);
    window.addEventListener("scroll", this.scrollServicios);
    window.addEventListener("scroll", this.scrollAbout);
    window.addEventListener("scroll", this.changeBackgroundPartners);
    window.addEventListener("scroll", this.storyScroll);
    window.addEventListener("scroll", this.divServices);
    window.addEventListener("scroll", this.scrollX);
    window.addEventListener("scroll", this.parrafosServ);
    window.addEventListener("scroll", this.blogScroll);
    window.addEventListener("scroll", this.iguanaScroll);
    window.addEventListener("load", this.lastestProyects);
    window.addEventListener("scroll", this.lastestProyects);
    window.addEventListener("scroll", this.teamatesAnimation);
    window.addEventListener("scroll", this.aboutTitle);
    window.addEventListener("scroll", this.lastTitle);
    window.addEventListener("scroll", this.contactoAn);
    window.addEventListener("scroll", this.servicesEnlaces);
    window.addEventListener("load", this.routeLoad);
    /*window.addEventListener("load", this.loadpage);
     window.addEventListener("scroll", this.scrollXS);
    window.addEventListener("scroll", this.scrollX);
    window.addEventListener("scroll", this.updateScrollX);
    */
    let cursor = document.querySelector(".cursorjont");
    let cursorinner = document.querySelector(".transp-cursor");
    let a = document.querySelectorAll(".portfolio-cont-c");
    let b = document.querySelectorAll(".frameCuatro");
    let c = document.querySelectorAll(".liga-stories");
    let d = document.querySelectorAll(".blogFrame");
    let sf1 = document.querySelectorAll(".servFrame1");
    let sf2 = document.querySelectorAll(".servFrame2");
    let sf3 = document.querySelectorAll(".servFrame3");

    document.addEventListener("mousemove", function (e) {
      let x = e.clientX;
      let y = e.clientY;
      cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%),
   calc(${e.clientY}px - 50%), 0)`;
    });

    document.addEventListener("mousemove", function (e) {
      let x = e.clientX;
      let y = e.clientY;
      cursorinner.style.left = x + "px";
      cursorinner.style.top = y + "px";
    });

    document.addEventListener("mousedown", function () {
      cursor.classList.add("click");
      cursorinner.classList.add("last-pro-cursor");
    });

    document.addEventListener("mouseup", function () {
      cursor.classList.remove("click");
      cursorinner.classList.remove("last-pro-cursor");
    });

    a.forEach((item) => {
      item.addEventListener("mouseover", () => {
        cursor.classList.add("conoce-mas-cont");
      });
      item.addEventListener("mouseleave", () => {
        cursor.classList.remove("conoce-mas-cont");
      });
    });

    b.forEach((item) => {
      item.addEventListener("mouseover", () => {
        cursor.classList.add("about-mas-cont");
      });
      item.addEventListener("mouseleave", () => {
        cursor.classList.remove("about-mas-cont");
      });
    });

    c.forEach((item) => {
      item.addEventListener("mouseover", () => {
        cursor.classList.add("stories-mas-cont");
      });
      item.addEventListener("mouseleave", () => {
        cursor.classList.remove("stories-mas-cont");
      });
    });

    d.forEach((item) => {
      item.addEventListener("mouseover", () => {
        cursor.classList.add("stories-mas-cont");
      });
      item.addEventListener("mouseleave", () => {
        cursor.classList.remove("stories-mas-cont");
      });
    });

    sf1.forEach((item) => {
      item.addEventListener("mouseover", () => {
        cursor.classList.add("stories-mas-cont");
      });
      item.addEventListener("mouseleave", () => {
        cursor.classList.remove("stories-mas-cont");
      });
    });

    sf2.forEach((item) => {
      item.addEventListener("mouseover", () => {
        cursor.classList.add("stories-mas-cont");
      });
      item.addEventListener("mouseleave", () => {
        cursor.classList.remove("stories-mas-cont");
      });
    });

    sf3.forEach((item) => {
      item.addEventListener("mouseover", () => {
        cursor.classList.add("stories-mas-cont");
      });
      item.addEventListener("mouseleave", () => {
        cursor.classList.remove("stories-mas-cont");
      });
    });
  },
  destroy() {
    window.removeEventListener("scroll", this.iscro);
    window.removeEventListener("scroll", this.scrollServicios);
    window.removeEventListener("scroll", this.scrollAbout);
    window.removeEventListener("scroll", this.changeBackgroundPartners);
    window.removeEventListener("scroll", this.storyScroll);
    window.removeEventListener("scroll", this.divServices);
    window.removeEventListener("scroll", this.scrollX);
    window.removeEventListener("scroll", this.parrafosServ);
    window.removeEventListener("scroll", this.blogScroll);
    window.removeEventListener("scroll", this.iguanaScroll);
    window.removeEventListener("scroll", this.lastestProyects);
    window.removeEventListener("scroll", this.teamatesAnimation);
    window.removeEventListener("scroll", this.aboutTitle);
    window.removeEventListener("scroll", this.lastTitle);
    window.removeEventListener("scroll", this.contactoAn);
    window.removeEventListener("scroll", this.servicesEnlaces);
    /*window.addEventListener("scroll", this.scrollXS);loadpage
    window.addEventListener("scroll", this.scrollX);
    window.removeEventListener("scroll", this.updateScrollX);*/
  },
};
</script>
<style >
.titulo-enlace-serv {
  position: fixed;
  z-index: 450;
  top: 40.26953125vh;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}
.titulo-enlace-serv .titulo-en {
  font-family: "Gramatika-Medium";
  font-size: 5.859375vh;
  line-height: 123.3%;
  color: #ffffff;
  margin: 0 0 3.41vh;
  transition: 0.25s;
}
.circle-serv {
  width: 20.21484375vh;
  height: 20.21484375vh;
  border-radius: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffffff;
  font-family: "Gramatika-Bold";
  font-size: 1.953125vh;
  line-height: 152.3%;
  text-align: center;
  color: #ffffff;
  transition: 0.25s;
}
.circle-serv:hover {
  background-color: #ef67c2;
  border: 2px solid transparent;
}
.queue-projects {
  width: 100%;
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: 1.5s;
  position: relative;
  justify-content: flex-end;
}
.conoce-mas-cont {
  border-radius: 30vh !important;
  /*width: 10.781vw!important;
    height: 10.781vw!important;*/
  width: 12.015625vh !important;
  height: 12.015625vh !important;
  border: 2px solid #ef67c2 !important;
  color: #ffffff !important;
  background-color: #ef67c2 !important;
  transition: 0.1s !important;
}
.about-mas-cont {
  border-radius: 30vh !important;
  /*width: 10.781vw!important;
    height: 10.781vw!important;*/
  width: 12.015625vh !important;
  height: 12.015625vh !important;
  border: 2px solid #3702fb !important;
  color: #ffffff !important;
  background-color: #3702fb !important;
  transition: 0.1s !important;
}
.stories-mas-cont {
  border-radius: 30vh !important;
  /*width: 10.781vw!important;
    height: 10.781vw!important;*/
  width: 12.015625vh !important;
  height: 12.015625vh !important;
  border: 2px solid #396aff !important;
  color: #ffffff !important;
  background-color: #396aff !important;
  transition: 0.1s !important;
}
.conoce-mas-cont:hover {
  background-color: #ef67c2;
  border: 2px solid transparent !important;
}
.cursorjont,
.transp-cursor,
.cursorinnerhover {
  width: 2vh;
  height: 2vh;
  border: 2px solid transparent;
  background-color: transparent;
  font-family: "Gramatika-Bold";
  font-size: 1.609375vh;
  line-height: 152.3%;
  text-align: center;
  color: transparent;
  position: fixed;
  z-index: 345;
  pointer-events: none;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: 0.1s !important;
}
/*SCROLL*/
.scroll-cont {
  width: 100%;
  z-index: auto;
  float: none;
  flex-shrink: 1;
  display: flex;
  margin: 0px;
  inset: auto;
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  padding: 0px 0px 0;
}
.fondoiguana {
  position: relative;
  /*transition: 0.5s;*/
}
#smooth-scroll {
  display: flex;
  transition: 0.25s ease-out;
  align-items: flex-start;
  /*transition: 1.25s;*/
  align-items: center;
  will-change: transform;
  width: max-content;
  height: 100vh;
  inset: 0px auto auto 0px;
  position: fixed;
  background-image: url("../assets/fondiu-1.svg");
  background-size: 390.385vh;
}
.historia-an {
  height: 100%;
  display: flex;
  background-color: #b2acfc;
}
.sticky-pro {
  background-color: #b2acfc;
  height: inherit;
}
::-webkit-scrollbar {
  width: 2vh !important;
  background: transparent;
  height: 1.26953125vh;
  margin: 0 0 3vh;
  z-index: 999;
  position: relative;
}

::-webkit-scrollbar-track {
  margin: 0 3vh 3vh;
  height: 2vh;
  width: 12vh;
  border-radius: 2vh;
  background: white;
}

::-webkit-scrollbar-thumb {
  background-color: #987ef3;
  border-radius: 2vh;
  border: 0.3vh solid #ffffff;
}

/*PAGE*/
.first-part {
  height: 100vh;
  /*background-image: url("../assets/fondiu-1.svg");
  background-size: 390.385vh;
  background-color: #e4e4f9;*/
  background-repeat: no-repeat;
  width: fit-content;
  display: flex;
  transition: 0.5s;
}
.bkcolor {
  display: flex;
  transition: 0.5s;
}
/***ACERCA DE */
.about-sec {
  background-color: #b2acfc;
}
.scroll-cont {
  height: 42440px;
  /*height: 9177.734375vh;
    height: 4792.734375vh; */
}

.whiteword {
  color: white;
}

@media (max-width: 1920px) {
  .scroll-cont {
    height: 42440px;
    /*
    height: 9177.734375vh;
    height: 4792.734375vh; */
  }
}

@media (max-width: 768px) {
  /*#FixedItems {
    display: none;
  }*/
  #smooth-scroll {
    background-repeat: no-repeat;
    background-position-x: -86vh;
    background-color:#e4e4f9;
    transition-timing-function: linear;
}
  .scroll-cont {
    /*height: 36440px;
    height: 25640px; */
    height: 27450px;
}
  .scroll-cont::-webkit-scrollbar {
    width: 15vh !important; /* width of the entire scrollbar */
    background: #b2acfc;
    height: 0vh;
  }
  .circle-serv {
    width: 9vh;
    height: auto;
    background-color: #ef67c2;
    border: 1px solid #ef67c2;
    /*padding: 3vh;*/
    padding: 5.8141vh 3.125vh 4.6478vh 3.26vh;
    line-height: 2.2vh;
  }
  .circle-serv:hover {
    background-color: #ef67c2;
    border: 2px solid transparent;
  }
  .titulo-enlace-serv .titulo-en {
    font-size: 4.076086956521739vh;
  }
  /* Cambios movil LG */
  .queue-projects {
  height: 78vh;
  }
}
</style>