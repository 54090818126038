<template>
  <div id="Servicios" class="Servicios">
    <div class="scroll-services">
      <div class="servicios-t-cont">
        <p>
          <span class="span-stc1" style="top:0">S</span>
          <span class="span-stc2" style="top: 0">e</span>
          <span class="span-stc3" style="top: -0">r</span>
          <span class="span-stc4" style="top: 0">v</span>
          <span class="span-stc5" style="top: -0">i</span>
          <span class="span-stc6" style="top: 0">c</span>
          <span class="span-stc7" style="top: -0">e</span>
          <span class="span-stc8" style="top: 0">s</span>
          <!--<span class="span-stc9" style="top: -950px">s</span>-->
        </p>
      </div>
    </div>
    <!--style="transform: scale(0.8)"-->
    <div class="servicios-secc">
      <p class="titulo tituloServ"><span>03</span>Our services.</p>
      <div class="servicios-cont">
        <div class="items divServices uno">
          <img
            src="../../assets/services/s1/img1.png"
            alt="img"
            class="devI1"
          />
          <img
            src="../../assets/services/s1/img2.png"
            alt="img"
            class="devI2"
          />
          <img
            src="../../assets/services/s1/img3.png"
            alt="img"
            class="devI3"
          />
          <img
            src="../../assets/services/s1/img4.png"
            alt="img"
            class="devI4"
          />
          <img
            src="../../assets/services/s1/img5.png"
            alt="img"
            class="devI5"
          />
          <img
            src="../../assets/services/s1/img2.png"
            alt="img"
            class="devI6"
          />
          <img
            src="../../assets/services/s1/img3.png"
            alt="img"
            class="devI7"
          />
          <img
            src="../../assets/services/s1/img2.png"
            alt="img"
            class="devI8"
          />
        </div>
        <div class="items divServices2 due">
          <img
            src="../../assets/services/s2/img1.png"
            alt="img"
            class="desI1"
          />
          <img
            src="../../assets/services/s2/img2.png"
            alt="img"
            class="desI2"
          />
          <img
            src="../../assets/services/s2/img3.png"
            alt="img"
            class="desI3"
          />
          <img
            src="../../assets/services/s2/img4.png"
            alt="img"
            class="desI4"
          />
          <img
            src="../../assets/services/s2/img5.png"
            alt="img"
            class="desI5"
          />
        </div>
        <div class="items divServices3 tre">
          <img
            src="../../assets/services/s3/img1.png"
            alt="img"
            class="mktI1"
          />
          <img
            src="../../assets/services/s3/img2.png"
            alt="img"
            class="mktI2"
          />
          <img
            src="../../assets/services/s3/img3.png"
            alt="img"
            class="mktI3"
          />
          <img
            src="../../assets/services/s3/img4.png"
            alt="img"
            class="mktI4"
          />
          <img
            src="../../assets/services/s3/img5.png"
            alt="img"
            class="mktI5"
          />
          <img
            src="../../assets/services/s3/img6.png"
            alt="img"
            class="mktI6"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    },

    devAnimations() {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let dv1 = document.getElementsByClassName("devI1")[0];
      let dv2 = document.getElementsByClassName("devI2")[0];
      let dv3 = document.getElementsByClassName("devI3")[0];
      let dv4 = document.getElementsByClassName("devI4")[0];
      let dv5 = document.getElementsByClassName("devI5")[0];
      let dv6 = document.getElementsByClassName("devI6")[0];
      let dv7 = document.getElementsByClassName("devI7")[0];
      let dv8 = document.getElementsByClassName("devI8")[0];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let resta = offset - 2018;
      let resta2 = offset - 1100;
      let mx1 = resta - 350;
      let mx2 = resta - 50;
      let mx3 = resta - 250;
      let mx4 = resta - 110;

/**20 */
      let pr1 = resta2 -  30;
      let pr2 = resta2 - 30;
      let pr3 = resta2 - 70;
      let pr4 = resta2 - 60;
      let pr5 = resta2 - 20;
      let pr6 = resta2 - 60;
      let pr7 = resta2 - 35;
      let pr8 = resta2 - 30;
      if (media768.matches) {
        dv1.style.left = -pr1 * 1.2 + "vh";
        dv2.style.left = -pr2 * 1.22 + "vh";
        dv3.style.left = -pr3 * 1.05 + "vh";
        dv4.style.left = -pr4 * 1.3 + "vh";
        dv5.style.left = -pr5 * 1.5 + "vh";
        dv6.style.left = -pr6 * 1.45 + "vh";
        dv7.style.left = -pr7 * 1.3 + "vh";
        dv8.style.left = -pr8 * 1.25 + "vh";
      } if (min768.matches) {
        dv1.style.left = -mx1 * 0.1 + "vh";
        dv2.style.left = -mx1 * 0.12 + "vh";
        dv3.style.left = -mx1 * 0.05 + "vh";
        dv4.style.left = -mx2 * 0.3 + "vh";
        dv5.style.left = -mx4 * 0.5 + "vh";
        dv6.style.left = -mx4 * 0.45 + "vh";
        dv7.style.left = -mx3 * 0.3 + "vh";
        dv8.style.left = -mx3 * 0.25 + "vh";
      }
    },

    desAnimations() {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let ds1 = document.getElementsByClassName("desI1")[0];
      let ds2 = document.getElementsByClassName("desI2")[0];
      let ds3 = document.getElementsByClassName("desI3")[0];
      let ds4 = document.getElementsByClassName("desI4")[0];
      let ds5 = document.getElementsByClassName("desI5")[0];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let resta = offset - 2277;
      let resta2 = offset - 1400;
      let mx1 = resta - 20;
      let mx2 = resta + 120;
      let mx3 = resta - 20;
      let mx4 = resta + 175;
      let mx5 = resta - 55;

      let pr1 = resta2 + 240;
      let pr2 = resta2 + 230;
      let pr3 = resta2 + 250;
      let pr4 = resta2 + 205;
      let pr5 = resta2 + 205;
      if (media768.matches) {
        ds1.style.left = -pr1 * 1.17 + "vh";
        ds2.style.left = -pr3 * 1.12 + "vh";
        ds3.style.left = -pr4 * 1.9 + "vh";
        ds4.style.left = -pr2 * 1.8 + "vh";
        ds5.style.left = -pr5 * 1.4 + "vh";
      } else if (min768.matches) {
        ds1.style.left = -mx1 * 0.17 + "vh";
        ds2.style.left = -mx3 * 0.12 + "vh";
        ds3.style.left = -mx4 * 0.9 + "vh";
        ds4.style.left = -mx2 * 0.8 + "vh";
        ds5.style.left = -mx5 * 0.4 + "vh";
      }
    },

    mktAnimations() {
      let media768 = window.matchMedia("(max-width: 768px)");
      let min768 = window.matchMedia("(min-width: 768px)");
      let mk1 = document.getElementsByClassName("mktI1")[0];
      let mk2 = document.getElementsByClassName("mktI2")[0];
      let mk3 = document.getElementsByClassName("mktI3")[0];
      let mk4 = document.getElementsByClassName("mktI4")[0];
      let mk5 = document.getElementsByClassName("mktI5")[0];
      let mk6 = document.getElementsByClassName("mktI6")[0];
      let number = event.target.documentElement.scrollTop;
      let offset = number * 0.09615384615384615;
      let resta = offset - 2277;
      let resta2 = offset - 1400;
      let mx1 = resta - 250;
      let mx2 = resta - 380;
      let mx3 = resta - 50;
      let mx4 = resta - 380;
      let mx5 = resta - 250;
      let mx6 = resta - 150;

      
      let pr1 = resta2 + 230;
      let pr2 = resta2 + 215;
      let pr3 = resta2 + 170;
      let pr4 = resta2 + 197;
      let pr5 = resta2 + 190;
      let pr6 = resta2 + 190;
      if (media768.matches) {
        mk1.style.left = -pr4 * 1.14 + "vh";
        mk2.style.left = -pr2 * 1.12 + "vh";
        mk3.style.left = -pr3 * 1.35 + "vh";
        mk4.style.left = -pr5 * 1.3 + "vh";
        mk5.style.left = -pr1 * 1.1 + "vh";
        mk6.style.left = -pr6 * 1.5 + "vh";
      } else if (min768.matches) {
        mk1.style.left = -mx4 * 0.14 + "vh";
        mk2.style.left = -mx2 * 0.12 + "vh";
        mk3.style.left = -mx3 * 0.35 + "vh";
        mk4.style.left = -mx5 * 0.3 + "vh";
        mk5.style.left = -mx1 * 0.1 + "vh";
        mk6.style.left = -mx6 * 0.5 + "vh";
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.devAnimations);
    window.addEventListener("scroll", this.desAnimations);
    window.addEventListener("scroll", this.mktAnimations);
  },
  destroy() {
    window.removeEventListener("scroll", this.devAnimations);
    window.removeEventListener("scroll", this.desAnimations);
    window.removeEventListener("scroll", this.mktAnimations);
  },
};
</script>
<style>
#Servicios {
  background: #b2acfc;
  display: flex;
  position: relative;
  margin-left: 0;
  height: 100%;
  transition: .25s ease-out;
}
.servicios-secc {
  transition: 1s;
  padding-left: 40vh;
}
.scroll-services {
  background-color: #b2acfc;
  /*position: sticky;
  width: 381.640625vh;*/
}
.servicios-secc p.titulo {
  display: none;
  font-family: Gramatika-Medium;
  font-size: 9.765625vh;
  line-height: 0;
  top: 14.453125vh;
  left: 13.4765625vh;
  letter-spacing: -0.015em;
  color: #987ef3;
  margin: 0;
  z-index: 100;
  position: relative;
  width: 62vh;
}
.servicios-secc p span {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 1.26953125vh;
  top: -1.4vh;
  position: relative;
  width: 2.2vh;
}
.servicios-t-cont {
  /* width: 190.8203125vh;*/
    height: 100vh;
    display: flex;
    align-items: flex-end;
  transition: 0.5s;
  background-color: #b2acfc;
}
.servicios-t-cont p {
  font-family: Gramatika-Medium;
  font-size: 97.65625vh;
  line-height: 97.65625vh;
  color: white;
  margin: 0;
}
.servicios-t-cont p span {
  position: relative;
  transition: .65s ease-out;
}
.servicios-cont {
  display: flex;
  /*background-color: #E4E4F9;*/
  height: 100%;
  align-items: center;
  /* width: 630vh;
    width: 445vh;
  width: 621vh;*/
    width: 466vh;
  justify-content: space-around;
}
.items {
  width: 97.55859375vh;
  height: 65.72265625vh;
  transition: 0.5s;
  background-image: none;
}
.serv-hover {
  width: 100%;
  height: 100%;
  background-image: none;
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*.items:nth-child(1) .serv-hover ,
.items:nth-child(2) .serv-hover ,
.items:nth-child(3) .serv-hover {
  background: rgba(228, 228, 249, 1);
  transition: 1.5s;
}*/

.items:nth-child(1) .serv-hover:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: inherit;
  content: " ";
}
.items:nth-child(2) .serv-hover:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: inherit;
  content: " ";
}
.items:nth-child(3) .serv-hover:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: inherit;
  content: " ";
}

.items:nth-child(1) .serv-hover {
  background: url("../../assets/serv-1.svg");
  background-size: 65.8203125vh;
  transition: background-color 0.5s ease-in-out;
}
.items:nth-child(2) .serv-hover {
  background: url("../../assets/serv-2.svg");
  background-size: 65.8203125vh;
  transition: background-color 0.5s ease-in-out;
}
.items:nth-child(3) .serv-hover {
  background: url("../../assets/serv-3.svg");
  background-size: 65.8203125vh;
  transition: background-color 0.5s ease-in-out;
}

.items:nth-child(1):hover .serv-hover {
  background-color: rgba(228, 228, 249, 0);
}
.items:nth-child(2):hover .serv-hover {
  background-color: rgba(228, 228, 249, 0);
}
.items:nth-child(3):hover .serv-hover {
  background-color: rgba(228, 228, 249, 0);
}
.btn-ser {
  background-image: url("../../assets/btn-1.svg");
  background-size: 4.6875vh;
  background-repeat: no-repeat;
  background-position-x: right;
  width: 15vh;
  height: 4.6875vh;
  display: flex;
  align-items: center;
  transition: 0.5s;
}
.btn-ser:hover {
  opacity: 0.5;
}
.btn-ser p {
  font-family: Gramatika-Bold;
  font-size: 1.5625vh;
  line-height: 152.3%;
  color: #b2acfc;
  margin: 0;
}
.serv-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;
  position: relative;
}
.items:hover .serv-c {
  filter: brightness(2.5);
}

.serv-hover .title {
  font-family: Gramatika-Black;
  font-size: 4.39453125vh;
  line-height: 123.3%;
  color: #ff91df;
  margin-bottom: 3vh;
  margin-top: 0;
}
.divServices,
.divServices2,
.divServices3 {
  position: relative;
}
img.devI1 {
  width: 76.953125vh;
  height: 81.25vh;
  z-index: 290;
}
img.devI2 {
  width: 7.32421875vh;
  height: 5.95703125vh;
  top: 53vh;
  z-index: 280;
}
img.devI3 {
  width: 9.765625vh;
  height: 7.91015625vh;
  top: 33vh;
  z-index: 280;
}
img.devI4 {
  width: 14.35546875vh;
  height: 14.35546875vh;
  top: 15vh;
  z-index: 300;
}
img.devI5 {
  width: 18.1640625vh;
  height: 14.74609375vh;
  top: 26vh;
  z-index: 280;
}
img.devI6 {
  width: 12.5vh;
  height: 10.15625vh;
  top: 10vh;
  z-index: 300;
}
img.devI7 {
  width: 4.78515625vh;
  height: 3.90625vh;
  top: 37vh;
  z-index: 280;
}
img.devI8 {
  width: 2.9296875vh;
  height: 2.34375vh;
  top: 48vh;
  z-index: 280;
}

img.desI1 {
  width: 52.83203125vh;
  /*height: 81.25vh;*/
  height: 81.25vh;
  z-index: 290;
}
img.desI2 {
  width: 10.83984375vh;
  height: 8.7890625vh;
  top: 37vh;
  z-index: 280;
}
img.desI3 {
  width: 14.35546875vh;
  height: 14.35546875vh;
  top: 46vh;
  z-index: 300;
}
img.desI4 {
  width: 34.375vh;
  height: 24.609375vh;
  top: 46vh;
  z-index: 300;
}
img.desI5 {
  width: 30.17578125vh;
  height: 42.7734375vh;
  top: 11.328125vh;
  z-index: 280;
}

img.mktI1 {
  width: 33.984375vh;
  height: 19.62890625vh;
  z-index: 280;
}
img.mktI2 {
  width: 59.47265625vh;
  height: 82.421875vh;
  z-index: 290;
}
img.mktI3 {
  width: 25.390625vh;
  height: 19.62890625vh;
  z-index: 280;
    top: 45vh;
}
img.mktI4 {
  width: 26.5625vh;
  height: 21.58203125vh;
  z-index: 300;
    top: 44vh;
}
img.mktI5 {
  width: 14.74609375vh;
  height: 14.74609375vh;
  z-index: 280;
    top: 7vh;
}
img.mktI6 {
  width: 12.5vh;
  height: 10.15625vh;
  z-index: 300;
    top: 60vh;
}
img.devI1,
img.devI2,
img.devI3,
img.devI4,
img.devI5,
img.devI6,
img.devI7,
img.devI8,
img.desI1,
img.desI2,
img.desI3,
img.desI4,
img.desI5,
img.mktI1,
img.mktI2,
img.mktI3,
img.mktI4,
img.mktI5,
img.mktI6 {
  position: absolute;
  transition: .25s  ease-out;
}
@media (max-width: 768px) {
  #Servicios {
    margin-left: 0;
  }
  .scroll-services {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .servicios-t-cont {
    /* width: 190.8203125vh; */
    align-items: center;
}
  .servicios-t-cont p {
    font-family: Gramatika-Medium;
    font-size: 54.3478vh;
    line-height: 54.3478vh;
  }
  .items {
    width: 56.25vh;
    height: 100vh;
  }
  .serv-hover .title {
    font-family: Gramatika-Black;
    font-size: 2.7093596059113296vh;
    line-height: 123.3%;
    color: #ff91df;
    margin-bottom: 2vh;
    margin-top: 0;
  }
  .servicios-secc {
    transition: 1s;
    padding-left: 10vh;
  }
  .servicios-secc p.titulo {
    display: none;
  }
  .servicios-cont {
    display: flex;
    height: 100%;
    align-items: center;
    width: fit-content;
    justify-content: flex-start;
  }

img.devI1 {
    top: 18.88vh;
    width: 56.25vh;
    height: 59.375vh;
}
img.devI2 {
    width: 4.94vh;
    height: 3.94vh;
    top: 46.059vh;
    z-index: 280;
}
img.devI3 {
    top: 42vh;
}
img.devI4 {
    width: 19.972vh;
    height: auto;
    top: 60.054vh;
    z-index: 300;
}
img.devI5 {
    width: 25.271vh;
    height: auto;
    top: 24.59vh;
    z-index: 500;
}
img.devI6 {
    width: 2.3097vh;
    height: 2.0097vh;
    top: 16.9836vh;
    z-index: 300;
}
img.devI7 {
    top: 57vh;
}
img.devI8 {
    display: none;
}


  img.desI1 {
    top: 17vh;
    width: 46.832031vh;
    height: 69.25vh;
    z-index: 290;
}
img.desI2 {
    width: 25.27vh;
    height: 20.51vh;
    top: 49.184vh;
    z-index: 500;
}
img.desI3 {
    width: 19.97vh;
    height: 19.97vh;
    top: 26.22282608695652vh;
    z-index: 300;
}
  img.desI4 {
    width: 24.375vh;
    height: 14.609375vh;
    top: 11vh;
    z-index: 300;
}
  img.desI5 {
    width: 21.175781vh;
    height: 32.773438vh;
    top: 60.328125vh;
    z-index: 280;
}


img.mktI1 {
    top: 12vh;
    width: 25.984375vh;
    height: auto;
    z-index: 280;
}
img.mktI2 {
    width: 48.0978vh;
    height: auto;
    z-index: 290;
    top: 18.478260869565215vh;
}
img.mktI3 {
    width: 13.9945vh;
    height: 10.733vh;
    z-index: 500;
    top: 65.625vh;
}
img.mktI4 {
    width: 25.2717vh;
    height: auto;
    z-index: 300;
    top: 24.048vh;
}
img.mktI5 {
    width: 19.972vh;
    height: 19.972vh;
    z-index: 500;
    top: 48.097vh;
}
img.mktI6 {
    width: 5.978vh;
    height: 4.891vh;
    z-index: 500;
    top: 27vh;
}

.servicios-t-cont p span {
  transition: .35s ease-out;
}
}
</style>