<template>
  <div id="Contacto" class="formcon contactoFinal">
    <div class="contact-cont">
      <div class="column">
        <div class="con-title">
          <p class="titulo"><span>06</span>Let´s talk about your project.</p>
        </div>
        <div class="data-cont">
          <div class="dir">
            <p>Gastón Madrid 157-6 Col. San Benito, Hermosillo, Sonora.</p>
          </div>
          <div class="dir">
            <p><a href="mailto:info@legrafica.mx">info@legrafica.mx</a></p>
            <p>
              <a href="tel:+526622754218">(662) 275 4218</a>
            </p>
          </div>
        </div>
      </div>
      <div class="column">
        <form @submit.prevent="enviarMail(name, phone, email, mensaje)">
          <div class="input-cont">
            <label>Nombre</label>
            <input type="text" v-model="name" name="nombre" />
          </div>
          <div class="input-cont">
            <label>Correo Eletrónico</label>
            <input type="text" v-model="email" name="email" />
          </div>
          <div class="input-cont">
            <label>Teléfono</label>
            <input type="text" v-model="phone" name="telefono" />
          </div>
          <div class="input-cont">
            <label>Mensaje</label>
            <textarea name="mensaje" v-model="mensaje" cols="30" rows="5"> </textarea>
          </div>
          <div class="btn-cont">
             <input class="btn-send" type="submit" 
             value="Enviar mensaje" />   
                
             <div v-if="this.status == 'success'">
              <p class="cart_msg cart_success">{{ this.msg }}</p>
            </div>
            <div v-if="this.status == 'error'">
              <p class="cart_msg cart_error">{{ this.msg }}</p>
            </div>
           <!-- <a href="/">
              <img
                src="../../assets/send-btn.svg"
                alt="flecha"
                class="btn"
                width="48"
                height="48"
              />
              <p>Enviar mensaje</p>
            </a>-->
          </div>
        </form>




      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      mensaje: "",
      status: "",
      msg: "",
    }
  },
  methods: {
    delStatus: function () {
      setTimeout(() => this.delMsgs(), 5000);
      /*this.close(), 3000);*/
    },
    delMsgs: function () {
      this.status = "";
      this.msg = "";
    },
    enviarMail: async function (name, phone, email, mensaje) {
      this.msg = "";
      this.status = "";
      if (name == undefined || name == "" || name == null) {
        this.msg = "El campo nombre no es valido.";
        this.status = "error";
      } else if (phone == undefined || phone == "" || phone == null) {
        this.msg = "El campo telefono no es valido.";
        this.status = "error";
      } else if (email == undefined || email == "" || email == null) {
        this.msg = "El campo correo no es valido.";
        this.status = "error";
      } else if (mensaje == undefined || mensaje == "" || mensaje == null) {
        this.msg = "El campo comentarios no es valido.";
        this.status = "error";
      } else {
        let data = {
          name: name,
          phone: phone,
          email: email,
          mensaje: mensaje,
          from: "formulario de página web",
        };
        let response = await this.$store.dispatch(
          "admin/enviarMailContacto",
          data
        );
        if (response.status == "success") {
          this.status = "success";
          this.msg = "Mensaje enviado con exito.";
          this.name = "";
          (this.phone = ""), (this.email = ""), (this.mensaje = "");
          this.delStatus();
        } else {
          this.status = "error";
          this.msg =
            "Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.";
        }
      }
    },
  },
};
</script>
<style>
#Contacto {
  background-color: white;
  background-image: url("../../assets/iguana-facha.svg");
  background-size: 92.67578125vh;
  background-repeat: no-repeat;
  background-position-x: right;
  display: flex;
  align-items: center;
  padding-left: 12.98828125vh;
    height: inherit;
}
.contact-cont {
  display: flex;
  position: relative;
}
.con-title {
  display: flex;
  align-items: flex-start;
  width: 55vh;
}
.con-title .titulo span {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 1.26953125vh;
  line-height: 103.8%;
  text-align: center;
  color: #ffb6ea;
  padding: 0 1.199219vh;
  margin-left: -3.7vh;
  position: relative;
  top: -3.2vh;
}
p.titulo {
  margin: 0;
  font-family: Gramatika-Medium;
  font-size: 9.765625vh;
  line-height: 81.3%;
  letter-spacing: -0.015em;
  color: #ffb6ea;
}
.data-cont {
  margin-top: 28.61328125vh;
}
.dir {
  width: 34.9609375vh;
  height: auto;
  background-image: url("../../assets/linea-xl.svg");
  background-repeat: no-repeat;
  background-size: 4.1015625vh;
  background-position-y: top;
  background-position-x: left;
  padding-top: 2.5390625vh;
  padding-bottom: 4.5390625vh;
}
.dir a {
  color: #000000;
  transition: 0.5s;
}
.dir a:hover {
  color: #e883cb;
}
.dir p {
  margin: 0;
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3671875vh;
  line-height: 213.3%;
  color: #000000;
}
.column:nth-child(2) {
  padding-top: 0.5vh;
  padding-right: 83.88671875vh;
}
.input-cont {
  display: flex;
  flex-direction: column;
  padding-bottom: 5.6640625vh;
}
.input-cont label {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3671875vh;
  line-height: 246.8%;
  color: #000000;
}
.input-cont input[type="text"] {
  width: 37.40234375vh;
  height: 4.4921875vh;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  border-bottom: 1px solid black;
}
.input-cont textarea {
  width: 37.40234375vh;
  height: 13.8671875vh;
  border: 0;
  border-bottom: 1px solid black;
  resize: none;
}
.formcon{
  transition: .5s;
}
/*
.btn-cont a {
  display: flex;
  align-items: center;
}
.btn-cont a .btn {
  width: 4.6875vh;
  height: 4.6875vh;
}
.btn-cont p {
  font-family: Gramatika-Bold;
  font-size: 1.5625vh;
  line-height: 152.3%;
  text-align: center;
  color: #000000;
  margin: 0;
  padding-left: 3vh;
}*/
.btn-send {
    font-family: Gramatika-Bold;
    font-size: 1.5625vh;
    line-height: 1vh;
    text-align: center;
    color: #000000;
    margin: 0;
    padding-left: 6vh;
    border: none;
    background-color: transparent;
    background-image: url('../../assets/send-btn.svg');
    background-repeat: no-repeat;
    background-size: 4.6875vh;
    height: 5vh; 
    cursor: pointer;
}.cart_msg{
  font-family: Gramatika-Bold;
  font-weight: normal;
    font-size: 2.3vh;
    line-height: 3vh;
}.cart_success{
  color: #159d74;
}
.cart_error{
  color: #ee1313;
}
.pc{
  display: block;
}
.mo{
  display: none;
}
@media (max-width: 768px) {
.pc{
  display: none;
}
.mo{
  display: block;
}
  #Contacto {
    background-image: none;
    padding-left: 0;
    padding-top: 20.68965517241379vh;
    align-items: flex-start;
    height: 100%;
    overflow: hidden;
  }
  .con-title {
    width: 42vh;
}
  .column:nth-child(2) {
    /*padding-right: 19.7vh;*/
    padding-right: 5.7vh;
  }
  p.titulo {
    display: none;
    font-size: 6.773399014778325vh;
  }
  .dir p {
    font-size: 1.4778325123152707vh;
  }
  .data-cont {
    margin-top: 29.768473vh;
  }.contact-cont {
    flex-direction: column;
    /*transition: .15s;*/
    background-image: url("../../assets/iguana-facha.svg");
    background-size: 76.63043478260869vh;
    background-repeat: no-repeat;
    background-position-y: bottom;
    padding-left: 6.114130434782608vh;
    padding-bottom: 87.630435vh;
    background-position-x: center;
    /*width: 100vw;*/
  }
  .btn-send{
    background-image: none;
    font-size: 0;
    background-image: url("../../assets/btn-success.svg");
    background-size: 15.2173vh;
    color: white;
    width: 15.2173vh;
    height: 15.2173vh;
    padding: 0;
    margin-left: 33%;
  }
  .btn-cont {
    margin-top: 3vh;
}
  @media (max-height:668px) {
    .contact-cont {
      width: 53vh;/**/
    }
  .input-cont input[type="text"] {
    width: 45.714286vh;
  }
  .input-cont textarea {
        width: 45.714286vh;

    height: 8.743842364532018vh;
  }
  }
  @media (min-height:668px) {
    .contact-cont {
      width: 43vh;/**/
    }
  .input-cont input[type="text"] {
    width: 35.71428571428571vh;
  }
  .input-cont textarea {
    width: 35.71428571428571vh;
    height: 8.743842364532018vh;
  }
  }
}
</style>