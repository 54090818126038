var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"integrantes",staticClass:"integrantes",attrs:{"id":"Integrantes"}},[_c('div',{staticClass:"integrantes-cont",staticStyle:{"left":"1%"},attrs:{"id":"integrantes-c"}},[_c('div',{staticClass:"frame frameUno teamateFr",attrs:{"to":"/portafolio"}},[_c('div',{staticClass:"imagen-frame",style:({
        'background-image':
          'url(' + require('../../assets/frame-1.png') + ')',
      }),attrs:{"alt":"image","width":"402","height":"599"}})]),_c('div',{staticClass:"frame frameTres teamateFr"},[_c('div',{staticClass:"imagen-frame",style:({
        'background-image':
          'url(' + require('../../assets/frame-3.png') + ')',
      }),attrs:{"alt":"image","width":"311","height":"599"}})]),_c('div',{staticClass:"frame frameCuatro teamateFr"},[_c('a',{ref:"noreferrer noopener",attrs:{"href":"/about","target":"_blank"}},[_c('div',{staticClass:"imagen-frame",style:({
          'background-image':
            'url(' + require('../../assets/frame-4.png') + ')',
        }),attrs:{"alt":"image","width":"621","height":"599"}})]),_c('div',{staticClass:"btn-cont"})]),_c('div',{staticClass:"frame frameCuatro teamateFr"},[_c('a',{ref:"noreferrer noopener",attrs:{"href":"/about","target":"_blank"}},[_c('div',{staticClass:"imagen-frame",style:({
          'background-image':
            'url(' + require('../../assets/frame-5.png') + ')',
        }),attrs:{"alt":"image","width":"621","height":"599"}})]),_c('div',{staticClass:"btn-cont"})]),_vm._m(0)]),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more-projects mo"},[_c('a',{attrs:{"href":"/about"}},[_c('div',{staticClass:"circulo-vm"},[_c('p',[_vm._v("Conoce más")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lr-cont"},[_c('div',{staticClass:"letras"},[_c('div',{staticClass:"sticky-l"},[_c('p',{staticClass:"wuno",attrs:{"id":"wuno"}},[_vm._v("We'll")]),_c('div',{staticClass:"l-pc"},[_c('p',{staticClass:"wdos",attrs:{"id":"wdos"}},[_vm._v("always find")]),_c('p',{staticClass:"pink wtres",attrs:{"id":"wtres"}},[_vm._v("creativity")])]),_c('div',{staticClass:"l-mo"},[_c('p',{staticClass:"wdosp",attrs:{"id":"wdos"}},[_vm._v("always")]),_c('p',{staticClass:"wtresp",attrs:{"id":"wtres"}},[_vm._v("find")]),_c('p',{staticClass:"wcuatro pink",attrs:{"id":"wcuatro"}},[_vm._v("creativity")])])])])])
}]

export { render, staticRenderFns }